<ng-container [ngSwitch]="mode">

    <ng-container *ngSwitchCase="PaymentSourceMode.ReadOnly">
        <lib-icon-bullet *ngIf="!loading"
            [icon]="Icon.Payment.CreditCard"
            [size]="IconSize.ThreeExtraSmall">
            <ng-container [ngPlural]="numPaymentMethods">              
                <ng-template ngPluralCase="=1" i18n="@@CommonPaymentMethodOneCase">{{numPaymentMethods}} Payment Method</ng-template>
                <ng-template ngPluralCase="other" i18n="@@CommonPaymentMethodOtherCase">{{numPaymentMethods}} Payment Methods</ng-template>
            </ng-container>
        </lib-icon-bullet>
    </ng-container>

    <ng-container *ngSwitchCase="PaymentSourceMode.Add">
        <div class="single-column-row" *ngIf="!loading">
            <ng-container *ngTemplateOutlet="addButton"></ng-container>
        </div>
        
        <div class="single-column-row" [attr.data-sb-qa]="'payment-methods-list-draggable'">
            <lib-drag-and-drop *ngIf="!loading && hasContent"
                [type]="PaymentSourceDisplayItem"
                orderBy="OrderIndex"
                (reordered)="Reordered($event)">
        
                <lib-drag-and-drop-item *ngFor="let paymentSource of paymentSources; index as i"
                    [id]="paymentSource.ID ?? undefined"
                    [value]="paymentSource">
                    <ng-container *ngTemplateOutlet="paymentSourceTemplate; context:{$implicit: paymentSource | method: CreateExtendedItem : i}"></ng-container> 
                    <lib-menu-button menu 
                        [prefixIcon]="Icon.Common.MoreVert"
                        [iconOnly]="true"
                        [hasCaretSuffix]="false"
                        [kind]="ButtonKind.Tertiary">
                        <lib-menu-item *ngIf="paymentSource.IsVerified"
                            i18n-label="@@CommonPaymentSourcesMakeDefault"
                            label="Make Default"
                            [prefixIcon]="Icon.Common.Check"
                            [disabled]="i === 0"
                            (clicked)="MakeDefault(paymentSource)">
                        </lib-menu-item>
                        <lib-menu-item *ngIf="!paymentSource.IsVerified"
                            i18n-label="@@CommonPaymentSourcesVerify"
                            label="Verify"
                            [prefixIcon]="Icon.Common.Check"
                            (clicked)="Verify(paymentSource)">
                        </lib-menu-item>
                        <lib-menu-item *ngIf="paymentSource.IsVerified && paymentSource.AutoPay && !forceAutoPay"
                            i18n-label="@@CommonPaymentSourcesDisableAutoPay"
                            label="Disable Auto Pay"
                            [prefixIcon]="Icon.Common.CloseCircle"
                            (clicked)="ToggleAutoPay(paymentSource, false)">
                        </lib-menu-item>
                        <lib-menu-item *ngIf="paymentSource.IsVerified && !paymentSource.AutoPay"
                            i18n-label="@@CommonPaymentSourcesEnableAutoPay"
                            label="Enable Auto Pay"
                            [prefixIcon]="Icon.Common.CheckCircle"
                            (clicked)="ToggleAutoPay(paymentSource, true)">
                        </lib-menu-item>
                        <lib-menu-item
                            i18n-label="@@CommonPaymentSourcesDelete"
                            label="Delete"
                            [prefixIcon]="Icon.Common.Delete"
                            (clicked)="DeleteItem(paymentSource)">
                        </lib-menu-item>
                    </lib-menu-button>
        
                </lib-drag-and-drop-item>
            </lib-drag-and-drop>
        </div> 
    </ng-container>
    <ng-container *ngSwitchCase="PaymentSourceMode.Payment">
        <div class="single-column-row" [attr.data-sb-qa]="'payment-methods-list'">
            <div [ngClass]="{'payment-source-item-selected': selectedPaymentSource?.ID === paymentSource.ID}" class="my-2 py-2 px-4 payment-source-item" *ngFor="let paymentSource of paymentSources;  index as i" (click)="PaymentSourceSelected(paymentSource)">
                <ng-container *ngTemplateOutlet="paymentSourceTemplate; context:{$implicit: paymentSource | method: CreateExtendedItem : i}"></ng-container>
            </div>
        </div>
    </ng-container>

</ng-container>


<ng-container *ngIf="!loading && isEmptyState && showEmptyState">
    <lib-empty-state
                [emptyStateGraphic]="EmptyStateGraphic.AccountInvoices"
                [useBackground]="false">
        <ng-container emptyStateText i18n="@@CommonNoPaymentMethodsLabel">You haven't added any payment methods</ng-container>
    </lib-empty-state>
</ng-container>


<ng-template #addButton>
    <lib-button
        [kind]="paymentContext === PaymentContext.TeacherPortal ? ButtonKind.Secondary : ButtonKind.Primary"
        [prefixIcon]="Icon.Common.Add"
        (clicked)="AddItem()"
        [qaTag]="'add-payments-sources'">
        <ng-container label
            i18n="@@CommonPaymentSourcesAddNew">
            Add New
        </ng-container>
    </lib-button>
</ng-template>

<ng-template #paymentSourceTemplate [type]="PaymentSourceDisplayItem" let-paymentSource >
<div  class="d-flex flex-column">
    <div class="d-flex align-items-center gap-1 flex-wrap">
        <lib-icon *ngLet="(paymentSource | paymentSourceIcon) as icon"
            [icon]="icon.Icon"
            [size]="IconSize.ExtraSmall">
        </lib-icon>
        <p class="typography-caption-large typography-semibold base-300" [attr.data-sb-qa]="'payment-source-issuer'">{{paymentSource.Issuer}}</p>
        <div class="d-flex align-items-baseline gap-1" [attr.data-sb-qa]="'payment-source-label'">
            <lib-status-label *ngIf="paymentSource | method: FindFirstVerifiedSource"
                [kind]="StatusKind.Default">
                <ng-container label>Default</ng-container>
            </lib-status-label>
            <lib-status-label *ngIf="paymentSource.AutoPay && paymentSource.IsVerified"
                [kind]="StatusKind.Success">
                <ng-container label i18n="@@CommonPaymentSourcesAutoPayOn">
                    Auto Pay Enabled
                </ng-container>
            </lib-status-label>
            <lib-status-label *ngIf="!paymentSource.IsVerified"
                [kind]="StatusKind.Danger">
                <ng-container label>Unverified</ng-container>
            </lib-status-label>
        </div>
    </div>
    <div class="d-flex align-items-baseline gap-2" [attr.data-sb-qa]="'payment-source-number-and-expiry'">
        <caption class="neutral-900">
            {{ paymentSource.DisplayText }}
        </caption>
        <caption *ngIf="paymentSource.Type === PaymentSourceType.CreditCard" class="neutral-900">
            exp {{ paymentSource.ExpiryDate! | method: FormatExpiry }}
        </caption>
    </div>
</div>
</ng-template>