import { Component, Output } from '@angular/core';
import { ApiRoute } from '@library/api';
import { ApiProgressSubject, BodyRequired, CancellableObservable, DialogBaseViewComponent, OneParameterRequired } from '@library/base';
import { IncludesStream, PracticeLogAttachmentInputItem } from '@library/data-models';
import { Observable, Subject, Subscription, take } from 'rxjs';
import { UploadData, UploadingDialogData } from '../upload.definitions';

@Component({
    selector: 'lib-uploading-dialog',
    templateUrl: './uploading-dialog.component.html',
    styleUrls: ['./uploading-dialog.component.scss']
})
export class UploadingDialogComponent extends DialogBaseViewComponent<UploadingDialogData> {
    //ui display vars
    private _totalSizeToUpload = 0;
    private _totalCurrentlyUploaded = 0;
    private _progressBarValue = 0;

    //ui configuration vars
    private _isFinalizing: boolean = false;
    private _hasUploadErrors = false;
    private _hasEmptyFiles: boolean = false;

    //trackers
    private _requestSubjects!: CancellableObservable<string>[];
    private _progressSubjects!: ApiProgressSubject<OneParameterRequired & BodyRequired<FormData>>[];
    private _progressSubscription!: (Subscription | null)[];

    private _dataUploaded!: number[];
    private _totalUploadedFiles: number = 0;
    private _currentlyFailedFileUploads!: number;

    //prepared files
    private _preparedData!: (UploadData | null)[];
    private _submitRoute!: ApiRoute<OneParameterRequired & BodyRequired<FormData>, (id: string) => string, FormData, undefined, undefined, string>;
    private _apiDeleteRoute?: ApiRoute<OneParameterRequired, (id: string) => string, FormData, undefined, undefined, boolean>;

    successfulUploadedFilesData: any[] = [];

    //subscribable callbacks ;)
    private _uploadCancelled = new Subject<boolean>();
    private _uploadPartialSuccess = new Subject<any[]>();
    private _uploadSuccess = new Subject<any[]>();

    //init
    override ngOnInit() {
        super.ngOnInit();
        this.InitTrackers();
        this.PrepareFilesForUpload();
        this.UploadFiles();
    }

    //functions
    private InitTrackers() {
        this._requestSubjects = [];
        this._progressSubjects = [];
        this._progressSubscription = [];
    }

    private InitUploadVariables() {
        this._totalSizeToUpload = 0;
        this._dataUploaded = [];
        this._totalCurrentlyUploaded = 0;
        this._progressBarValue = 0;

        this._hasUploadErrors = false;
        this._currentlyFailedFileUploads = 0;

        this._isFinalizing = false;
    }
    
    PrepareFilesForUpload() {
        this._submitRoute = this.data.ApiSubmitRoute as ApiRoute<OneParameterRequired & BodyRequired<FormData>, (id: string) => string, FormData, undefined, undefined, string>;
        this._apiDeleteRoute = this.data.ApiDeleteRoute;
        this._preparedData = [];
        if(this.data.Files) {
            for(let i = 0; i < this.data.Files.length; ++i) {
                this._preparedData.push(this.data.Files[i]);
            }
        }

        if(this.data.Files === undefined || this.data.Files?.length === 0) {
            this._hasEmptyFiles = true
        }
    }

    UploadFiles() {
        this.InitUploadVariables();

        for(let i = 0; i < this._preparedData.length; ++i) {
            if(this._preparedData[i]) {
                const preparedData = this._preparedData[i] as UploadData;
                this._totalSizeToUpload += preparedData.File.size;                    

                const param = (preparedData.ID !== null && preparedData.ID !== undefined) ? `${preparedData.ID}` : "";
                const formData = new FormData();
                formData.append("input", JSON.stringify(preparedData.InputItem));
                formData.append("file", preparedData.File);

                this._progressSubjects[i] = this._submitRoute.MakeProgressSubject();
                this._progressSubscription[i] = this._progressSubjects[i].subscribe( x => {
                    if(x.Response.type == 1) {
                        this._dataUploaded[i] = x.Response.loaded;
                        this.CalculateProgressBarValue();
                    }
                });

                this._requestSubjects[i] = this._submitRoute.CallWithSubject({
                    Parameter: param,
                    Body: formData,
                }, undefined, this._progressSubjects[i]);

                this._requestSubjects[i].subscribe({
                    next: (uploadedID: any) => {
                        this.successfulUploadedFilesData.push({ID: uploadedID, Filename: preparedData.File.name});

                        //NOTE: We do not use splice to delete here because we need to keep indicies alinged for other uploads!
                        // this._preparedData.splice(i, 1);
                        this._preparedData[i] = null;
                        
                        this._progressSubscription[i]?.unsubscribe();
                        this._progressSubscription[i] = null;

                        this._totalUploadedFiles++;
                        this.CheckIfAllFilesUploadedSuccessfully();
                    },
                    error: () => {
                        this._currentlyFailedFileUploads++;
                        this.CheckIfAllFilesUploadedSuccessfully();
                    }
                });
            }
        }
    }

    CalculateProgressBarValue() {
        this._totalCurrentlyUploaded = 0;

        this._dataUploaded.forEach(value => {
            this._totalCurrentlyUploaded += value
        });

        this._progressBarValue = Math.min(100, (this._totalCurrentlyUploaded + 0.0)/this._totalSizeToUpload * 100);

        if(this._totalCurrentlyUploaded >= this._totalSizeToUpload) {
            this._isFinalizing = true;
        }
    }

    IgnoreContinueAction() {
        this._uploadPartialSuccess.next(this.successfulUploadedFilesData);
        this.DismissAction();
    }

    CancelUploadAction() {
        this._requestSubjects.forEach(i => {
            i.Cancel();
        });

        if(this.data.DeleteOnCancel && this._apiDeleteRoute) {
            this.successfulUploadedFilesData.forEach( value => {
                this._apiDeleteRoute?.Call({Parameter: value.ID});
            });
        }

        this._uploadCancelled.next(true);
        this.DismissAction();
    }

    TryAgainAction() {
        this.UploadFiles()
    }

    GetFailedFiles(firstThreeFiles: boolean): string[] {
        return firstThreeFiles ? this.failedFiles.slice(0,3) : this.failedFiles.slice(3);
    }

    get progressBarValue() {
        return this._progressBarValue;
    }

    get isFinalizing(): boolean {
        return this._isFinalizing;
    }

    get hasUploadErrors() {
        return this._hasUploadErrors;
    }

    public get hasEmptyFiles(): boolean {
        return this._hasEmptyFiles;
    }

    private get _totalFilesToUploadCount() {
        return this._preparedData.length;
    };

    get failedFiles(): string[] {
        return (this._preparedData.filter(f => f !== null) as UploadData[]).map(f => f?.File.name);
    }

    CheckIfAllFilesUploadedSuccessfully() {
        if(this._totalUploadedFiles == this._totalFilesToUploadCount) {
            this.AllUploadsSuccessful()
        } else if(this._totalUploadedFiles + this._currentlyFailedFileUploads == this._totalFilesToUploadCount) {
            this._hasUploadErrors = true;
        }
    }

    AllUploadsSuccessful() {
        this._uploadSuccess.next(this.successfulUploadedFilesData);
        this.DismissAction();
    }

    get uploadCancelled(): Observable<boolean> {
        return this._uploadCancelled.pipe(take(1));
    }
    get uploadPartialSuccess(): Observable<any[]> {
        return this._uploadPartialSuccess.pipe(take(1));
    }
    get uploadSuccess(): Observable<any[]> {
        return this._uploadSuccess.pipe(take(1));
    }
}