import { Component, ElementRef, EventEmitter, HostListener, inject, Input, Output, ViewChild } from '@angular/core';
import { NumberFormatInfo } from '@library/data-models';
import { LocalizationDecimalPipe, LocalizationService } from '@library/localization';
import { FormFieldInputBaseComponent } from '../form-field-input-base.component';

@Component({
    selector: 'lib-input-number',
    templateUrl: './form-field-number.component.html',
    styleUrls: ['./form-field-number.component.scss']
})
export class FormFieldNumberComponent extends FormFieldInputBaseComponent<number | null> {
    @ViewChild('numberinput') numberInput!: ElementRef;
    
    private _decimalFormatter!: LocalizationDecimalPipe;
    protected _validationRegex = new RegExp(/[0-9]/, 'g');
    private lastValid = '';

    protected _LocalizationService!: LocalizationService;
    protected _NumberFormatInfo!: NumberFormatInfo;

    minDecimalPlaces: number = 0;
    maxDecimalPlaces: number = 0;

    @Input() applyFormatting: boolean = true;

    @Output() valueChanged: EventEmitter<void> = new EventEmitter();

    private _groupSeparatorRegex!: RegExp;

    constructor() {
        super();

        this._decimalFormatter = new LocalizationDecimalPipe();
        this._LocalizationService = inject(LocalizationService);
        this._NumberFormatInfo = this._LocalizationService.NumberFormatInfo;

        this._groupSeparatorRegex = new RegExp(`[${this._NumberFormatInfo.GroupSeparator}]`, 'g');
    }

    public override OnBlur(): void {
        if (this.viewControl.value != null && this.viewControl.value.trim() != '') {
            this.FormatInput(this.viewControl.value);
        } else {
            this.formControl.setValue(null);
        }

        super.OnBlur();
    }

    public FormatInput(value: string) {
        if (value !== '') {
            value = value.replace(this._NumberFormatInfo.GroupSeparator!, '')
                         .replace(this._NumberFormatInfo.DecimalSeparator!, '.');

            let numberValue: number = Number(value);
            if (!isNaN(numberValue)) {
                this.formControl.setValue(numberValue);
                this.change.emit(numberValue);

                if (this.applyFormatting) {
                    let formattedValue = this.ValueFormatter(numberValue);
                    this.viewControl.setValue(formattedValue);
                    this.lastValid = formattedValue;
                } else {
                    this.lastValid = value;
                }
            }
        }
    }

    public ValueFormatter(numberValue: number): string {
        return this._decimalFormatter.transform(numberValue, this.minDecimalPlaces, this.maxDecimalPlaces);
    }

    OnFocus(): void {
        this.onInput();
    }

    override writeValue(numberValue: number): void {
        let formattedValue = this.ValueFormatter(numberValue);
        this.viewControl.setValue(formattedValue);
        this.lastValid = formattedValue;
    }

    override ObservePristine(): void {
        // This is done below in the host listener instead
    }

    SetFocus(): void {
        if(this.numberInput) {
            this.numberInput.nativeElement.focus();
        }
    }

    @HostListener('input', ['$event'])
    onInput() {
        // Firefox 1.0+
        //var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

        let eventValue = this.viewControl.value;
        if (!eventValue) {
            if (this.lastValid != '') {
                this.valueChanged.emit();
                this.formControl.isDirty = true;
            }

            this.lastValid = '';
        } else {
            eventValue = eventValue.replace(this._groupSeparatorRegex, '');
            // on input, run regex to only allow certain characters and format
            const validValue = (eventValue.match(this._validationRegex) || []).join('');

            if (validValue) {
                if (this.lastValid != validValue) {
                    this.valueChanged.emit();
                    this.formControl.isDirty = true;
                }

                this.lastValid = validValue;
                this.viewControl.setValue(validValue);
            } else {
                this.viewControl.setValue(this.lastValid);
            }
        }
    }
}
