import { Injectable } from '@angular/core';
import { CurrencyFormatInfo, DayOfWeekEnum, LocalizationInfo, NumberFormatInfo, PercentFormatInfo } from '@library/data-models';
import { SBDateTime } from './date-time/sb-date-time';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {

    private _LocalizationInfo!: LocalizationInfo;
    private _firstDayOfWeekIndex: number = 0;

    Initialize(localizationInfo: LocalizationInfo): void {
        if (this.ValidateAllLocalizationInfoWasProvided(localizationInfo)) {
            this._LocalizationInfo = {...localizationInfo};
            SBDateTime.SetLocalizationInfo(this._LocalizationInfo);
            this._firstDayOfWeekIndex = this.GetFirstDayOfWeek();
        } else {
            throw Error('Localization information received from API was faulty.');
        }
    }

    private ValidateAllLocalizationInfoWasProvided(localizationInfo: LocalizationInfo): Boolean {
        return this.NotNullOrUndefined(localizationInfo) &&
            this.NotNullOrUndefined(localizationInfo.AMDesignator) &&
            this.NotNullOrUndefined(localizationInfo.AbbreviatedDayNames) &&
            this.NotNullOrUndefined(localizationInfo.AbbreviatedMonthGenitiveNames) &&
            this.NotNullOrUndefined(localizationInfo.AbbreviatedMonthNames) &&
            this.NotNullOrUndefined(localizationInfo.CurrencyFormatInfo) &&
            this.NotNullOrUndefined(localizationInfo.DayNames) &&
            this.NotNullOrUndefined(localizationInfo.DistanceUnit) &&
            this.NotNullOrUndefined(localizationInfo.FirstDayOfWeek) &&
            this.NotNullOrUndefined(localizationInfo.Is12Hour) &&
            this.NotNullOrUndefined(localizationInfo.LongDatePattern) &&
            this.NotNullOrUndefined(localizationInfo.LongTimePattern) &&
            this.NotNullOrUndefined(localizationInfo.MonthDayPattern) &&
            this.NotNullOrUndefined(localizationInfo.MonthGenitiveNames) &&
            this.NotNullOrUndefined(localizationInfo.MonthNames) &&
            this.NotNullOrUndefined(localizationInfo.NumberFormatInfo) &&
            this.NotNullOrUndefined(localizationInfo.PMDesignator) &&
            this.NotNullOrUndefined(localizationInfo.PercentFormatInfo) &&
            this.NotNullOrUndefined(localizationInfo.ShortDatePattern) &&
            this.NotNullOrUndefined(localizationInfo.ShortTimePattern) &&
            this.NotNullOrUndefined(localizationInfo.YearMonthPattern);
    }

    private NotNullOrUndefined(property: any) {
        return property != null && property != undefined;
    }

    private GetFirstDayOfWeek(): number {
        switch(this._LocalizationInfo.FirstDayOfWeek){
            case DayOfWeekEnum.Sunday:
                return 0;
            case DayOfWeekEnum.Monday:
                return 1;
            case DayOfWeekEnum.Tuesday:
                return 2;
            case DayOfWeekEnum.Wednesday:
                return 3;
            case DayOfWeekEnum.Thursday:
                return 4;
            case DayOfWeekEnum.Friday:
                return 5;
            case DayOfWeekEnum.Saturday:
                return 6;
            default:
                return 0;
        }
    }

    get AMDesignator(): string {
        return this._LocalizationInfo.AMDesignator!;
    }

    get AbbreviatedDayNames(): string[] {
        return this._LocalizationInfo.AbbreviatedDayNames;
    }

    get AbbreviatedMonthGenitiveNames(): string[] {
        return this._LocalizationInfo.AbbreviatedMonthGenitiveNames;
    }

    get AbbreviatedMonthNames(): string[] {
        return this._LocalizationInfo.AbbreviatedMonthNames;
    }

    get CurrencyFormatInfo(): CurrencyFormatInfo {
        return this._LocalizationInfo.CurrencyFormatInfo!;
    }

    get DayNames(): string[] {
        return this._LocalizationInfo.DayNames;
    }

    get DistanceUnit(): string {
        return this._LocalizationInfo.DistanceUnit;
    }

    get FirstDayOfWeek(): string {
        return this._LocalizationInfo.FirstDayOfWeek;
    }

    get Is12Hour(): boolean {
        return this._LocalizationInfo.Is12Hour;
    }

    get LongDatePattern(): string {
        return this._LocalizationInfo.LongDatePattern!;
    }

    get LongTimePattern(): string {
        return this._LocalizationInfo.LongTimePattern!;
    }

    get MonthGenitiveNames(): string[] {
        return this._LocalizationInfo.MonthGenitiveNames;
    }

    get MonthNames(): string[] {
        return this._LocalizationInfo.MonthNames;
    }

    get NumberFormatInfo(): NumberFormatInfo {
        return this._LocalizationInfo.NumberFormatInfo!;
    }

    get PMDesignator(): string {
        return this._LocalizationInfo.PMDesignator!;
    }

    get PercentFormatInfo(): PercentFormatInfo {
        return this._LocalizationInfo.PercentFormatInfo!;
    }

    get ShortDatePattern(): string {
        return this._LocalizationInfo.ShortDatePattern!;
    }

    get ShortTimePattern(): string {
        return this._LocalizationInfo.ShortTimePattern!;
    }

    get YearMonthPattern(): string {
        return this._LocalizationInfo.YearMonthPattern!;
    }

    get MonthDayPattern(): string {
        return this._LocalizationInfo.MonthDayPattern!;
    }

    get LocalizationInfo(): LocalizationInfo {
        return this._LocalizationInfo;
    }

    get MaxCalendarDateISOString(): string {
        return this._LocalizationInfo.MaxCalendarDate;
    }

    get firstDayOfWeekIndex(): number {
        return this._firstDayOfWeekIndex;
    }
}
