
import { CalendarSearchOptions } from "@library/data-models";
import { AnnouncementKind } from "./definitions/announcement.definitions";
import { CalendarView } from "./definitions/calendar.definitions";
import { TemporaryReportDownloads } from "./definitions/report.definitions";
import { StorageKey } from "./managers/storage.manager";

export const StorageBaseKeys = {
    TemporaryReports: StorageKey.Local<TemporaryReportDownloads>({ expireAfterMinutes: 2 }),
    CurrentAnnouncements: StorageKey.Local<AnnouncementKind[]>(),
    CalendarView: StorageKey.Local<CalendarView>({expireAfterRefresh: true}),
    CalendarSearchOptions: StorageKey.Session<CalendarSearchOptions | null>(),
}