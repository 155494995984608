<div class="flex-wrap gap-1" [ngClass]="{'d-flex justify-content-between w-100 align-items-center': !isMobile || orientation === Orientation.Horizontal, 'd-grid': isMobile}">
    <div class="d-flex" [ngClass]="{'justify-content-start': !isMobile || orientation === Orientation.Horizontal, 'flex-column gap-2': isMobile && orientation === Orientation.Vertical}">
        <ng-content select="[rowLeft]"> </ng-content>
    </div>

    <ng-content select="[rowCenter]"></ng-content>

    <div class="d-flex brand-500" [ngClass]="{'gap-1': isMobile, 'gap-2': !isMobile, 'justify-content-end': !isMobile || orientation === Orientation.Horizontal, 'flex-column': isMobile &&  orientation === Orientation.Vertical}">
        <ng-content select="[rowRight]"> </ng-content>
    </div>    
</div>
