<div class="card w-100 shadow scss-card-margin" #card [attr.data-sb-qa]="qaTag">
  <div class="card-body p-lg-4 p-3">
    <ng-container *ngIf="isLoading"> <!-- Review: Right now this is a default sckelecton, we probably want to add a projectable loading state, or make this better -->
      <div class="form-field-input-loading--small"></div>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <h4 #header [ngClass]="{'pb-4': header.hasChildNodes()}">
        <ng-content select="[header]"></ng-content>
      </h4>
      <ng-content></ng-content>
    </ng-container>
  </div>
</div>
