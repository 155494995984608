import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";


import { BaseViewComponent, ButtonKind, ButtonSize, IconSize, IconItem } from "@library/base";

@Component({
    template: '',
})

export class ButtonBaseComponent extends BaseViewComponent implements OnInit {
    @ViewChild('button', { static: false}) button: ElementRef | undefined;
    @ViewChild(MatMenuTrigger, {static: false}) matMenuTrigger!: MatMenuTrigger;
    optionsMenuTriggerRef!: MatMenuTrigger;

    private _buttonClass: string = '';
    private _toolTipTextDisabledString!: string;
    private _menuTrigger!: MatMenu;
    private _disabledValue: boolean = false;
    private _debouncing: boolean = false;
    protected _iconSize: IconSize = IconSize.Medium;
    @Input() prefixIcon: IconItem | null = null;
    @Input() suffixIcon: IconItem | null = null;
    @Input() stopPropagation: boolean = true;
    @Input() urlLink: string = ''; //used for external link button. right clicking allows us to see open in new tab.
    @Input() qaTag:string ="";
    @Input() allowTextWrap: boolean = false;
    @Input() badgeText!: string;
    @Input() isViewOnly: boolean = false;
    @Input()
    set menuTrigger(value: MatMenu){
        this._menuTrigger = value;
    }

    private _kind: ButtonKind = ButtonKind.Primary;
    @Input()
    set kind(value: ButtonKind){
        this._kind = value;
        this.SetButtonClass();
    }

    private _size: ButtonSize = ButtonSize.Medium
    @Input()
    set size(value: ButtonSize){
        this._size = value;
        this.SetButtonClass();
    }

    private _disabled: boolean = false;
    @Input()
    set disabled(value: boolean) {
        this._disabledValue = value;
        this.SetDisabledState();
    }

    private _loading: boolean = false;
    @Input()
    set loading(value: boolean){
        this.SetLoadingValue(value);
    }

    // only set this for font awesome icon buttons
    private _iconOnly: boolean = false;
    @Input()
    set iconOnly(value: boolean) {
        this._iconOnly = value;        
        this.SetTooltipText(); 
    }

    private _toolTipText: string = '';
    @Input()
    set toolTipText(value: string) {
        this._toolTipTextDisabledString = value;        
    }

    @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter();
    @Output() menuClosed: EventEmitter<void> = new EventEmitter();

    override ngOnInit(): void {
        super.ngOnInit();
        this.SetButtonClass();
        this.SetTooltipText();
    }

    ButtonClicked(event: MouseEvent, optionsMenuTrigger?: MatMenuTrigger): void {
        if (this.disabled) {
            event.preventDefault();
            if (this.stopPropagation) {
                event.stopPropagation();
            }
        } else {
            if (this.urlLink) {
                if(event.ctrlKey || event.shiftKey) {
                    this.Debounce();
                    return;
                } else {
                    event.preventDefault();
                }
            }
            if (optionsMenuTrigger && this.menuTrigger) {
                this.optionsMenuTriggerRef = optionsMenuTrigger;
            }
            if (!this.disabled && !this.isViewOnly) {
                this.clicked.emit(event);
            }
            if (this.stopPropagation) {
                // This seems to work better for buttons, since this doesn't stop e.g. lib-select menus closing when a button is pressed,
                // but it does appear to stop menus closing. If you encounter problems with a button and are tempted to add stopPropagation
                // here, please talk to Marc
                event.preventDefault();
            }
            if (this.isDesktop) {
                this.button?.nativeElement?.blur();
            }

            this.Debounce();
        }
    }

    private Debounce() {
        this._debouncing = true;
        this.SetDisabledState();

        setTimeout(() => {
            this._debouncing = false;
            this.SetDisabledState();
        }, 250);
    }

    private SetDisabledState() {
        this._disabled = this._disabledValue || this._loading || this._debouncing;
        this.SetButtonClass();
        this.SetTooltipText();
        this.DetectChanges();
    }

    SetTooltipText(): void {
        this._toolTipText = (this.disabled || this._iconOnly) ? this._toolTipTextDisabledString : '';
    }

    MenuClosed(): void {
        this.menuClosed.emit();
    }
    
    protected SetButtonClass(): void {
        this._buttonClass = this._kind.concat(' ').concat(this._size);
        if (!this.iconOnly && this._iconSize){
            switch(this.size){
                case ButtonSize.Small:
                    this._iconSize = IconSize.TwoExtraSmall;
                    break;
                case ButtonSize.Medium:
                    this._iconSize = IconSize.ExtraSmall;
                    break;
                case ButtonSize.Large:
                    this._iconSize = IconSize.Small;
                    break;
                default:
                    this._iconSize = IconSize.ExtraSmall;
            }
        }
        if(this.iconOnly){
            this._buttonClass = this._buttonClass.concat(' ').concat('btn-icon');
            // if there's only one icon, then we want the width and height to be the same. otherwise, we want the width to be dynamic.
            if (!(this.prefixIcon && this.suffixIcon)) {
                switch(this._size) {
                    case ButtonSize.Small:
                        this._buttonClass = this._buttonClass.concat(' ').concat('icon-button-small');
                        break;
                    case ButtonSize.Medium:
                        this._buttonClass = this._buttonClass.concat(' ').concat('icon-button-medium');                        
                        break;
                    case ButtonSize.Large:
                        this._buttonClass = this._buttonClass.concat(' ').concat('icon-button-large');
                        break;
                    default:
                        this._buttonClass = this._buttonClass.concat(' ').concat('icon-button-medium');
                }
            }
        }        
        if (this.disabled) {
            this._buttonClass = this._buttonClass.concat(' ').concat('disabled');
        }
    }
    
    private SetLoadingValue(loading: boolean): void {
        this._loading = loading;
        this.SetDisabledState();
        this.SetButtonClass();
        this.SetTooltipText();
    }

    get buttonClass(): string {
        return this._buttonClass;
    }

    get iconOnly(): boolean {
        return this._iconOnly;
    }

    get toolTipText(): string {
        return this._toolTipText;
    }

    get loading(): boolean {
        return this._loading;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    get size(): ButtonSize {
        return this._size;
    }

    get kind(): ButtonKind {
        return this._kind;
    }

    get menuTrigger(): MatMenu {
        return this._menuTrigger;
    }
    protected get iconSize(): IconSize{
        return this._iconSize;
    }    
}
