<!-- ================================================ -->
<!-- ReadME -->
<!-- ================================================ -->
<!-- 
    The Login Widget has currently 7 screens in a single html page. The display has been made more verbose at the expense
    of slightly more code duplication of buttons. This was a trade-off for maintainability, as the original version
    because quite tangled once we had to add mfa, or edit the text on a specific screen.
    
    The top of the file determins if its in website builder, hosted website, or external website widget mode
    The bottom of the file contains the different screens that the login widget contains.

    For quick access, the screens are tagged:
    #ALI Already Logged In
    #PL Portal Login
    #RP Reset Password
    #RPTY Reset Password Thank You
    #SA Select Account
    #2SV 2-Step Verification
    #MR Manual Redirect
-->


<!-- ================================================ -->
<!-- Widget Switching Logic -->
<!-- ================================================ -->
<div [ngClass]="{
        'scss-website-background-color-only': kind !== WebsiteBlockLoginFormType.AccentBackgroundDefault,
        'scss-block-color-variable-font': false && kind === WebsiteBlockLoginFormType.AccentBackgroundDefault,
        'scss-block-background-color-variable': kind === WebsiteBlockLoginFormType.AccentBackgroundDefault
    }">

    <ng-container *ngIf="websiteMode === WebsiteMode.ReadOnly">
        <div  class="d-flex position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="websiteMode === WebsiteMode.Live && renderContext.RenderMode === WebsiteRenderMode.Website">
        <div class="widget-block-live">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="websiteMode === WebsiteMode.Live && renderContext.RenderMode === WebsiteRenderMode.Widget">
        <div class="d-flex flex-column w-100 h-100 justify-content-center widget-block-live">
            <ng-container *ngTemplateOutlet="widgetBlockContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="websiteMode === WebsiteMode.Preview">
        <lib-design-blocks-action-menu
            [disableMoveUp]="disableMoveUp"
            [disableMoveDown]="disableMoveDown"
            (moveDownClicked)="MoveDownClicked()"
            (moveUpClicked)="MoveUpClicked()"
            (deleteClicked)="DeleteClicked()">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
    <div class="v3 d-flex flex-row w-100 justify-content-center py-5 widget-block">
        <div class="scss-spacing-widget-block align-items-center"
            [ngClass]="{
            'd-flex flex-sm-row flex-column scss-widget-block-height': kind !== WebsiteBlockLoginFormType.Default,
            'p-3 border shadow-sm scss-spacing-widget-block-no-media background-base-0': kind !== WebsiteBlockLoginFormType.MediaLeft && kind !== WebsiteBlockLoginFormType.MediaRight,
            'scss-spacing-widget-block-media': kind === WebsiteBlockLoginFormType.MediaLeft || kind === WebsiteBlockLoginFormType.MediaRight,
            'scss-spacing-widget-block-accent': kind === WebsiteBlockLoginFormType.AccentBackgroundDefault,
            'scss-sizing-login-widget-block-desktop': (kind !== WebsiteBlockLoginFormType.MediaLeft && kind !== WebsiteBlockLoginFormType.MediaRight) && !isMobile,
            'w-100 mx-3': isMobile,
            'scss-spacing-widget-block-top-border': kind === WebsiteBlockLoginFormType.Default
            }">

            <div class="d-flex flex-column w-100 h-100 justify-content-center">
                <ng-container *ngTemplateOutlet="loginFormContent"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loginFormContent>
    <div class="d-flex w-100 h-100 justify-content-center gap-3 scss-widget-block-content-container"
        [ngClass]="{
            'flex-row ': (!isMobileOrTablet || inSidebar) && kind !== WebsiteBlockLoginFormType.MediaRight,
            'flex-column align-items-center': isMobileOrTablet && !inSidebar,
            'flex-row-reverse ': (!isMobileOrTablet || inSidebar) && kind === WebsiteBlockLoginFormType.MediaRight
        }">
        <div *ngIf="kind === WebsiteBlockLoginFormType.MediaLeft || kind === WebsiteBlockLoginFormType.MediaRight"
            class="shadow-sm widget-media-image"
            [ngClass]="{
                'scss-sizing-widget-block-desktop-media': !isMobile,
                'w-100': isMobile
            }"
            libScrollToView
            [currMode]="websiteMode"
            (mouseenter)="MouseEnterGeneralImage()"
            (mouseleave)="MouseLeaveGeneralImage()">

            <lib-image-action-menu-general 
                [showActionMenu]="showGeneralImageActionMenu"
                [disableImageDetails]="disableImageDetails"
                (imageActionClicked)="ImageActionClicked($event)">
            </lib-image-action-menu-general>

            <ng-content select="[image]"></ng-content>
        </div>

        <div class="d-flex flex-column w-100 h-100 justify-content-center background-base-0"
            [ngClass]="{
                'p-3 border shadow-sm': kind === WebsiteBlockLoginFormType.MediaLeft || kind === WebsiteBlockLoginFormType.MediaRight,
                'w-100': kind !== WebsiteBlockLoginFormType.MediaLeft && kind !== WebsiteBlockLoginFormType.MediaRight,
                'scss-sizing-widget-block-desktop-media': (kind === WebsiteBlockLoginFormType.MediaLeft || kind === WebsiteBlockLoginFormType.MediaRight) && !isMobile
            }">

            <ng-container *ngIf="websiteMode !== WebsiteMode.Live">
                <ng-container *ngTemplateOutlet="loginFormFormContent"></ng-container>
            </ng-container>

            <ng-container *ngIf="websiteMode=== WebsiteMode.Live">
                <iframe #iframe style="min-height: 347px; transition: height .1s" class="w-100" frameBorder="0" scrolling="no" [src]="data.Url + '&sandboxed=false' ! | trustUrl" (load)="Load(iframe)"></iframe>
            </ng-container>
        </div> 
    </div>
</ng-template>

<ng-template #widgetBlockContent>
    <div class="d-flex w-100 h-100 justify-content-center">
        <div class="d-flex flex-column w-100 h-100 justify-content-center background-base-0 base-300 p-3">
            <ng-container *ngTemplateOutlet="loginFormFormContent"></ng-container>
        </div>
    </div>
</ng-template>



<!-- ================================================ -->
<!-- The screens of the actual login widget -->
<!-- ================================================ -->
<ng-template #loginFormFormContent>



    
    <!-- ================================================ -->
    <!-- #ALI Already Logged In -->
    <!-- ================================================ -->
    <ng-container *ngIf="loginFormMode === LoginFormMode.AlreadyLoggedIn">

        <!-- TODO: Unlocked Padlock Icon Here -->


        <h1 [ngClass]="{'align-self-center': true}" class="mb-2"
            i18n="@@LoginWidgetPortalLoginTitle">
            Portal Login
        </h1>

        <ng-container *ngIf="portalType === PortalType.StudentPortal">
            <!-- TODO: New Text -->
            <p class="text-center" i18n="@@LoginWidgetStudentPortalWelcomeBackMessage">
                Welcome back {{alreadyLoggedInFirstName}}! You're already logged in to {{alreadyLoggedInSchoolName}}. Click below to access the #student# portal.
            </p>
        </ng-container>
        <ng-container *ngIf="portalType === PortalType.TeacherPortal">
            <!-- TODO: New Text -->
            <p class="text-center" i18n="@@LoginWidgetTeacherPortalWelcomeBackMessage">
                Welcome back {{alreadyLoggedInFirstName}}! You're already logged in to {{alreadyLoggedInSchoolName}}. Click below to access the #teacher# portal.
            </p>
        </ng-container>

        <div class="d-flex flex-column w-100 pt-2">
            <lib-button
                class="scss-widget-primary-btn-accent-color"
                [kind]="ButtonKind.Primary"
                [size]="ButtonSize.FullWidth"
                [loading]="apiInProgress"
                [disabled]="apiInProgress"
                [isViewOnly]="isViewOnly"
                (clicked)="ReturnToPortal()">
                <ng-container label i18n="@@CommonGoToPortalButton">Go to Portal</ng-container>
            </lib-button>
        </div>

        <div class="d-flex w-100 pt-3 login-widget-link-btn"
            [ngClass]="{
            'justify-content-center': !isMobile,
            'flex-row': !isMobile,
            'flex-column': isMobile
            }">
            <!-- single-column-row -->
            <div>
                <lib-button
                    [kind]="ButtonKind.Link"
                    [size]="ButtonSize.FullWidth"
                    [disabled]="apiInProgress"
                    [isViewOnly]="isViewOnly"
                    (clicked)="Logout()">
                    <ng-container label i18n="@@LoginWidgetUseAnotherAccountButtonText">Use Another Account</ng-container>
                </lib-button>
            </div>
        </div>
    </ng-container>



    
    <!-- ================================================ -->
    <!-- #PL Portal Login -->
    <!-- ================================================ -->
    <ng-container *ngIf="loginFormMode === LoginFormMode.Normal">
        <h1 [ngClass]="{'align-self-center': true}" class="mb-2"
            i18n="@@LoginWidgetPortalLoginTitle">
            Portal Login
        </h1>

        <form [formGroup]="loginForm">
            <div class="single-column-row-design-block pb-3">
                <lib-input-text [formControl]="loginForm.controls.EmailAddress"
                    [maxCharacterLength]="190"
                    [errorMessages]="emailError"
                    [isOptional]="false">
                    <ng-container label i18n="@@CommonEmail">
                        Email
                    </ng-container>
                </lib-input-text>
            </div>
            
            <div class="single-column-row-design-block pb-3">
                <lib-input-secret [formControl]="loginForm.controls.Password"
                    [errorMessages]="passwordError"
                    [isOptional]="false">
                    <ng-container label i18n="@@CommonPassword">
                        Password
                    </ng-container>
                </lib-input-secret>
            </div>

            <lib-button
                [kind]="ButtonKind.Link"
                [size]="ButtonSize.Medium"
                [disabled]="loginForm.disabled && !isViewOnly"
                [isViewOnly]="isViewOnly"
                (clicked)="Forgot()">
                <ng-container label i18n="@@LoginWidgetForgotPasswordButton">Forgot Password?</ng-container>
            </lib-button>

            <hr>

            <div class="single-column-row-design-block">
                <lib-checkbox [formControl]="loginForm.controls.PublicComputer" i18n="@@LoginWidgetUsingPublicSharedDeviceLabel">
                    I am using a public or shared device
                </lib-checkbox>               
            </div>
        </form>

        <!-- On Recaptcha Error, display message: Reload the page, or wait 2 minutes. -->
        <lib-error
            [control]="recaptchaErrorForm.controls.Captcha"
            [errors]="captchaErrors">
        </lib-error>

        <lib-error
            [control]="recaptchaNeededErrorForm.controls.CaptchaNeeded"
            [errors]="captchaNeededErrors">
        </lib-error>
    
        <div class="d-flex flex-column w-100 pt-2">
            <lib-button class="scss-widget-primary-btn-accent-color"
                #loginButton
                [kind]="ButtonKind.Primary"
                [size]="ButtonSize.FullWidth"
                [loading]="loginForm.disabled && !isViewOnly && !recaptchaneededError && !reCAPTCHAReady"
                [disabled]="loginForm.disabled && !isViewOnly"
                [isViewOnly]="isViewOnly"
                (clicked)="LoginButton()"
                (mousedown)="DisableButton(loginButton)">
                <ng-container label i18n="@@CommonLoginButton">Log in</ng-container>
            </lib-button>
        </div>

        <p *ngIf="websiteMode === WebsiteMode.Live" class="typography-body-small pt-2">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </p>
    </ng-container>

    <!-- This needs to not be removed from dom as the screens change! -->
    <!-- TODO: according to docs, we _should_ be able to use a formcontrol here but it doesnt seem to work with the invisible captcha, should spend more time -->
    <re-captcha *ngIf="websiteMode === WebsiteMode.Live"
        size="invisible"
        errorMode="handled"
        (resolved)="HandleCAPTCHA($event)" 
        (errored)="HandleCAPTCHAError($event)">
    </re-captcha>



    
    <!-- ================================================ -->
    <!-- #RP Reset Password -->
    <!-- ================================================ -->
    <ng-container *ngIf="loginFormMode === LoginFormMode.ForgotPassword">
        <h1 [ngClass]="{'align-self-center': true}" class="mb-2"
            i18n="@@LoginWidgetResetPasswordTitle">
            Reset Password
        </h1>

        <p class="text-center py-3" i18n="@@LoginWidgetResetPasswordMessage">
            Please enter the email address of your account, and we will send you instructions to reset your password.
        </p>

        <form [formGroup]="resetPasswordForm">
            <div class="single-column-row pb-3">
                <lib-input-text [formControl]="resetPasswordForm.controls.EmailAddress"
                    [maxCharacterLength]="190"
                    [errorMessages]="emailError"
                    [isOptional]="false">
                    <ng-container label i18n="@@CommonEmail">
                        Email
                    </ng-container>
                </lib-input-text>
            </div>
        </form>

        <!-- On Recaptcha Error, display message: Reload the page, or wait 2 minutes. -->
        <lib-error
            [control]="recaptchaErrorForm.controls.Captcha"
            [errors]="captchaErrors">
        </lib-error>

        <lib-error
            [control]="recaptchaNeededErrorForm.controls.CaptchaNeeded"
            [errors]="captchaNeededResetErrors">
        </lib-error>

        <div class="d-flex flex-column w-100 pt-2">
            <lib-button
                class="scss-widget-primary-btn-accent-color"
                [kind]="ButtonKind.Primary"
                [size]="ButtonSize.FullWidth"
                [loading]="resetPasswordForm.disabled && !isViewOnly && !recaptchaneededError && !reCAPTCHAReady"
                [disabled]="resetPasswordForm.disabled && !isViewOnly"
                [isViewOnly]="isViewOnly"
                (clicked)="ResetPasswordButton()">
                <ng-container label i18n="@@CommonLoginWidgetRequestResetLink">Request reset link</ng-container>
            </lib-button>
        </div>

        <div class="d-flex w-100 pt-3 login-widget-link-btn"
            [ngClass]="{
            'justify-content-center': !isMobile,
            'flex-row': !isMobile,
            'flex-column': isMobile
            }">
            <div>
                <lib-button
                    [kind]="ButtonKind.Link"
                    [size]="ButtonSize.FullWidth"
                    [disabled]="resetPasswordForm.disabled && !isViewOnly"
                    [isViewOnly]="isViewOnly"
                    (clicked)="Normal()">
                    <ng-container label i18n="@@CommonLoginWidgetBackToLogin">Back to Login</ng-container>
                </lib-button>
            </div>
        </div>

        <p *ngIf="websiteMode === WebsiteMode.Live" class="typography-body-small pt-2">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </p>
    </ng-container>



    
    <!-- ================================================ -->
    <!-- #RPTY Reset Password Thank You-->
    <!-- ================================================ -->
    <ng-container *ngIf="loginFormMode === LoginFormMode.ResetPasswordThankYou">
        <h1 [ngClass]="{'align-self-center': true}" class="mb-2"
            i18n="@@LoginWidgetResetPasswordTitle">
            Reset Password
        </h1>

        <p class="text-center py-3" i18n="@@LoginWidgetResetPasswordSentMessage">
            We sent a password reset link to the email associated with your account. If you don't see an email within the next 5 minutes, please check your spam or junk folder.
        </p>

        <div class="d-flex w-100 pt-2 login-widget-link-btn"
            [ngClass]="{
            'justify-content-center': !isMobile,
            'flex-row': !isMobile,
            'flex-column': isMobile
            }">

            <div>
                <lib-button
                    [kind]="ButtonKind.Link"
                    [size]="ButtonSize.FullWidth"
                    [isViewOnly]="isViewOnly"
                    (clicked)="Normal()">
                    <ng-container label i18n="@@CommonLoginWidgetBackToLogin">Back to Login</ng-container>
                </lib-button>
            </div>
        </div>
    </ng-container>



    
    <!-- ================================================ -->
    <!-- #SA Select Account -->
    <!-- ================================================ -->
    <ng-container *ngIf="loginFormMode === LoginFormMode.MultipleJWTs">
        <h1 [ngClass]="{'align-self-center': true}" class="mb-2"
            i18n="@@LoginWidgetSelectAccountTitle">
            Select Account
        </h1>

        <p class="text-center py-3" i18n="@@CommonLoginWidgetPleaseSelectAccountMessage">
            Your email address is associated with multiple accounts. Please choose the account you wish to use for this session.
        </p>

        <form [formGroup]="multipleAccountSelectionForm">
            <lib-input-select
                [formControl]="multipleAccountSelectionForm.controls.AccountSelection"
                placeholderText="Select an account"
                [errorMessages]="continueError"
                [isOptional]="false">
                <ng-container label i18n="@@CommonLoginWidgetAccount">
                    Account
                </ng-container>
                <lib-input-select-item *ngFor="let account of availableAccounts"
                    [value]="account">
                    <!-- //init it to the index of option, as string because 0 = false for a value. -->
                    <ng-container>
                        <!-- TODO: need to break out for translations -->
                        <!-- TODO: New Design once Widgets are restricted to per school. -->
                        {{account.FirstName}} {{account.LastName}}, {{account.ProfileMode}} at {{ account.SchoolName }}
                    </ng-container>
                </lib-input-select-item>
            </lib-input-select>
        </form>

        <div class="d-flex flex-column w-100 pt-2">
            <lib-button #continueButton
                class="scss-widget-primary-btn-accent-color"
                [kind]="ButtonKind.Primary"
                [size]="ButtonSize.FullWidth"
                [loading]="multipleAccountSelectionForm.disabled && !isViewOnly"
                [disabled]="multipleAccountSelectionForm.disabled && !isViewOnly"
                [isViewOnly]="isViewOnly"
                (clicked)="Continue()"
                (mousedown)="DisableButton(continueButton)">
                <ng-container label i18n="@@CommonContinueButton">Continue</ng-container>
            </lib-button>
        </div>

        <div class="d-flex w-100 pt-3 login-widget-link-btn"
            [ngClass]="{
            'justify-content-center': !isMobile,
            'flex-row': !isMobile,
            'flex-column': isMobile
            }">

            <div>
                <lib-button
                    [kind]="ButtonKind.Link"
                    [size]="ButtonSize.FullWidth"
                    [disabled]="multipleAccountSelectionForm.disabled && !isViewOnly"
                    [isViewOnly]="isViewOnly"
                    (clicked)="Normal()">
                    <ng-container label i18n="@@CommonLoginWidgetUseAnotherEmail">Use Another Email</ng-container>
                </lib-button>
            </div>
        </div>
    </ng-container>



    
    <!-- ================================================ -->
    <!-- #2SV 2-Step Verification -->
    <!-- ================================================ -->
    <ng-container *ngIf="loginFormMode === LoginFormMode.MfaRequired">
        <h1 [ngClass]="{'align-self-center': true}" class="mb-2"
            i18n="@@CommonLoginWidgetTwoFactorAuthenticationTitle">
            2-Factor Authentication (2FA)
        </h1>

        <p class="text-center py-3" i18n="@@CommonTwoFactorAuthenticationEnter6DigitCode">Please enter the 6-digit code from your authentication app.</p>

        <lib-mfa-input [formControl]="mfaForm.controls.Code"></lib-mfa-input>

        <div class="d-flex flex-column w-100 pt-4">
            <lib-button #continueMFAButton
                class="scss-widget-primary-btn-accent-color"
                [kind]="ButtonKind.Primary"
                [size]="ButtonSize.FullWidth"
                [loading]="mfaForm.disabled && !isViewOnly"
                [disabled]="mfaForm.disabled && !isViewOnly"
                [isViewOnly]="isViewOnly"
                (clicked)="ContinueMFA()"
                (mousedown)="DisableButton(continueMFAButton)">
                <ng-container label i18n="@@CommonContinueButton">Continue</ng-container>
            </lib-button>
        </div>

        <div class="d-flex w-100 pt-3 login-widget-link-btn"
            [ngClass]="{
            'justify-content-center': !isMobile,
            'flex-row': !isMobile,
            'flex-column': isMobile
            }">

            <div>
                <lib-button
                    [kind]="ButtonKind.Link"
                    [size]="ButtonSize.FullWidth"
                    [disabled]="mfaForm.disabled && !isViewOnly"
                    [isViewOnly]="isViewOnly"
                    (clicked)="Normal()">
                    <ng-container label i18n="@@CommonCancel">Cancel</ng-container>
                </lib-button>
            </div>
        </div>
    </ng-container>





    <!-- ================================================ -->
    <!-- #MR Manual Redirect -->
    <!-- ================================================ -->
    <ng-container *ngIf="loginFormMode === LoginFormMode.ManualRedirect">
        <h1 [ngClass]="{'align-self-center': true}" class="mb-2"
            i18n="@@LoginWidgetPortalLoginTitle">
            Portal Login
        </h1>

        <p class="text-center pt-3" i18n="@@LoginWidgetLoginManualRedirect">
            You are logged in. Click "Continue" if you were not automatically redirected.
        </p>

        <div class="d-flex flex-column w-100 pt-4">
            <lib-button
                class="scss-widget-primary-btn-accent-color"
                [kind]="ButtonKind.Primary"
                [size]="ButtonSize.FullWidth"
                (clicked)="RedirectToTargetURL(manualRedirectJWT)">
                <ng-container label i18n="@@CommonContinueButton">Continue</ng-container>
            </lib-button>
        </div>
    </ng-container>
</ng-template>
