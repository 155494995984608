import { SVGSource } from "./svg-source-definitions";

// how icons align to the text next to them
export enum IconBulletAlignment {
    Start = "Start",
    Center = "Center",
    Baseline = "Baseline",
    End = "End"
}

// styles of text in lib-icon-bullet
export enum IconBulletTextStyle {
    Normal = "Normal",
    Pre= "Pre",
    BreakWord="BreakWord"
}

export enum IconSize {
    ThreeExtraSmall = "icon-three-extra-small",
    TwoExtraSmall = "icon-two-extra-small",
    ExtraSmall = "icon-extra-small",
    Small = "icon-small",
    Medium = "icon-medium",
    Large = "icon-large",
    ExtraLarge = "icon-extra-large",
    TwoExtraLarge = "icon-two-extra-large",
    Huge = "icon-huge"
}



export interface IconItem {
    Name: string;
    Path: string;
}



export const Icon = {
    Common: {
        Check: {
            Name: 'Check',
            Path: SVGSource.Check
        },
        Edit: {
            Name: 'Edit',
            Path: SVGSource.Pen
        },
        ExpandMore: {
            Name: 'ExpandMore',
            Path: SVGSource.ExpandMore
        },
        ExpandLess: {
            Name: 'ExpandLess',
            Path: SVGSource.ExpandLess
        },
        Star: {
            Name: 'Star',
            Path: SVGSource.Star
        },
        CaretDown: {
            Name: 'CaretDown',
            Path: SVGSource.CaretDown
        },
        Close: {
            Name: 'Close',
            Path: SVGSource.Close
        },
        Fullscreen: {
            Name: 'Fullscreen',
            Path: SVGSource.Fullscreen
        },
        FullScreenExit: {
            Name: 'FullScreenExit',
            Path: SVGSource.FullScreenExit
        },
        
        HoldCash: {
            Name: 'HoldCash',
            Path: SVGSource.HoldCash,
        },
        ShowChart: {
            Name: 'ShowChart',
            Path: SVGSource.ShowChart,
        },
        Groups: {
            Name: 'Groups',
            Path: SVGSource.Groups,
        },
        Apartment: {
            Name: 'Apartment',
            Path: SVGSource.Apartment
        },
        DirectionsCar: {
            Name: 'DirectionsCar',
            Path: SVGSource.DirectionsCar
        },
        Church: {
            Name: 'Church',
            Path: SVGSource.Church
        },
        CheckCircle: {
            Name: 'CheckCircle',
            Path: SVGSource.CheckCircle
        },
        RemoveCircle: {
            Name: 'RemoveCircle',
            Path: SVGSource.RemoveCircle
        },
        CloseCircle: {
            Name: 'CloseCircle',
            Path: SVGSource.CloseCircle
        },
        Alarm: {
            Name: 'Alarm',
            Path: SVGSource.Alarm
        },
        ChevronRight: {
            Name: 'ChevronRight',
            Path: SVGSource.ChevronRight
        },
        CakeOutline: {
            Name: 'CakeOutline',
            Path: SVGSource.CakeOutline
        },
        WebAsset: {
            Name: 'WebAsset',
            Path: SVGSource.WebAsset
        },
        CheckCircleOutline: {
            Name: 'CheckCircleOutline',
            Path: SVGSource.CheckCircleOutline
        },
        ClipboardPaste: {
            Name: 'ClipboardPaste',
            Path: SVGSource.ClipboardPaste
        },
        Time: {
            Name: 'Time',
            Path: SVGSource.Time
        },
        DoubleArrowLeft: {
            Name: 'DoubleArrowLeft',
            Path: SVGSource.DoubleArrowLeft
        },
        DoubleArrowRight: {
            Name: 'DoubleArrowRight',
            Path: SVGSource.DoubleArrowRight
        },
        ChevronLeft: {
            Name: 'ChevronLeft',
            Path: SVGSource.ChevronLeft
        },
        ArrowDownward: {
            Name: 'ArrowDownward',
            Path: SVGSource.ArrowDownward
        },
        FromBottom: {
            Name: 'FromBottom',
            Path: SVGSource.FromBottom
        },
        ArrowBack: {
            Name: 'ArrowBack',
            Path: SVGSource.ArrowBack
        },
        ArrowForward: {
            Name: 'ArrowForward',
            Path: SVGSource.ArrowForward
        },
        RotateLeft: {
            Name: 'RotateLeft',
            Path: SVGSource.RotateLeft
        },
        RotateRight: {
            Name: 'RotateRight',
            Path: SVGSource.RotateRight
        },
        Download: {
            Name: 'Download',
            Path: SVGSource.Download
        },
        Upload: {
            Name: 'Upload',
            Path: SVGSource.Upload
        },
        ArrowUpward: {
            Name: 'ArrowUpward',
            Path: SVGSource.ArrowUpward
        },
        OpenInNew: {
            Name: 'OpenInNew',
            Path: SVGSource.OpenInNew
        },
        InputBox: {
            Name: 'InputBox',
            Path: SVGSource.InputBox
        },
        OutputBox: {
            Name: 'OutputBox',
            Path: SVGSource.OutputBox
        },
        BlockAlt: {
            Name: 'BlockAlt',
            Path: SVGSource.BlockAlt
        },
        Menu: {
            Name: 'Menu',
            Path: SVGSource.Menu
        },
        NotificationsOutline: {
            Name: 'NotificationsOutline',
            Path: SVGSource.NotificationsOutline
        },
        Bolt: {
            Name: 'Bolt',
            Path: SVGSource.Bolt
        },
        BookClosedOutline: {
            Name: 'BookClosedOutline',
            Path: SVGSource.BookClosedOutline
        },
        AutoStoriesOutline: {
            Name: 'AutoStoriesOutline',
            Path: SVGSource.AutoStoriesOutline
        },
        InventoryOutline: {
            Name: 'InventoryOutline',
            Path: SVGSource.InventoryOutline
        },
        Brush: {
            Name: 'Brush',
            Path: SVGSource.Brush
        },
        BullhornOutline: {
            Name: 'BullhornOutline',
            Path: SVGSource.BullhornOutline
        },
        PiePiece: {
            Name: 'PiePiece',
            Path: SVGSource.PiePiece
        },
        Calendar: {
            Name: 'Calendar',
            Path: SVGSource.Calendar
        },
        PhotoCameraOutline: {
            Name: 'PhotoCameraOutline',
            Path: SVGSource.PhotoCameraOutline,
        },
        DirectionsCarOutline: {
            Name: 'DirectionsCarOutline',
            Path: SVGSource.DirectionsCarOutline
        },
        ChartLinear: {
            Name: 'ChartLinear',
            Path: SVGSource.ChartLinear
        },
        DoubleArrowDown: {
            Name: 'DoubleArrowDown',
            Path: SVGSource.DoubleArrowDown
        },
        DoubleArrowUp: {
            Name: 'DoubleArrowUp',
            Path: SVGSource.DoubleArrowUp
        },
        ChurchOutline: {
            Name: 'ChurchOutline',
            Path: SVGSource.ChurchOutline
        },
        CircleOutline: {
            Name: 'CircleOutline',
            Path: SVGSource.CircleOutline
        },
        CloudUploadOutline: {
            Name: 'CloudUploadOutline',
            Path: SVGSource.CloudUploadOutline
        },
        Code: {
            Name: 'Code',
            Path: SVGSource.Code
        },
        SettingsOutline: {
            Name: 'SettingsOutline',
            Path: SVGSource.SettingsOutline
        },
        CogWheels: {
            Name: 'CogWheels',
            Path: SVGSource.CogWheels
        },
        TableColumnsView: {
            Name: 'TableColumnsView',
            Path: SVGSource.TableColumnsView
        },
        ColumnThree: {
            Name: 'ColumnThree',
            Path: SVGSource.ColumnThree
        },
        ChatOutline: {
            Name: 'ChatOutline',
            Path: SVGSource.ChatOutline
        },
        ChatTypingOutline: {
            Name: 'ChatTypingOutline',
            Path: SVGSource.ChatTypingOutline
        },
        ContentCopy: {
            Name: 'ContentCopy',
            Path: SVGSource.ContentCopy
        },
        Polyline: {
            Name: 'Polyline',
            Path: SVGSource.Polyline
        },
        Refresh: {
            Name: 'Refresh',
            Path: SVGSource.Refresh
        },
        Notification: {
            Name: 'Notification',
            Path: SVGSource.Notification
        },
        Cake: {
            Name: 'Cake',
            Path: SVGSource.Cake
        },
        BookClosed: {
            Name: 'BookClosed',
            Path: SVGSource.BookClosed
        },
        AutoStories: {
            Name: 'AutoStories',
            Path: SVGSource.AutoStories
        },
        Bullhorn: {
            Name: 'Bullhorn',
            Path: SVGSource.Bullhorn
        },
        Calculate: {
            Name: 'Calculate',
            Path: SVGSource.Calculate
        },
        PhotoCamera: {
            Name: 'PhotoCamera',
            Path: SVGSource.PhotoCamera
        },
        CaretUp: {
            Name: 'CaretUp',
            Path: SVGSource.CaretUp
        },
        BarChart: {
            Name: 'BarChart',
            Path: SVGSource.BarChart
        },
        Circle: {
            Name: 'Circle',
            Path: SVGSource.Circle
        },
        AssignmentPerson: {
            Name: 'AssignmentPerson',
            Path: SVGSource.AssignmentPerson
        },
        TimeFilled: {
            Name: 'TimeFilled',
            Path: SVGSource.TimeFilled
        },
        Cloud: {
            Name: 'Cloud',
            Path: SVGSource.Cloud
        },
        ChatText: {
            Name: 'ChatText',
            Path: SVGSource.ChatText
        },
        CreditCardFilled: {
            Name: 'CreditCardFilled',
            Path: SVGSource.CreditCardFilled
        },
        Error: {
            Name: 'Error',
            Path: SVGSource.Error
        },
        GraduationCap: {
            Name: 'GraduationCap',
            Path: SVGSource.GraduationCap
        },
        Home: {
            Name: 'Home',
            Path: SVGSource.Home
        },
        Remove: {
            Name: 'Remove',
            Path: SVGSource.Remove
        },
        Om: {
            Name: 'Om',
            Path: SVGSource.Om
        },
        StarAndCrescent: {
            Name: 'StarAndCrescent',
            Path: SVGSource.StarAndCrescent
        },
        StarOfDavid: {
            Name: 'StarOfDavid',
            Path: SVGSource.StarOfDavid
        },
        University: {
            Name: 'University',
            Path: SVGSource.University
        },
        VideoCam: {
            Name: 'VideoCam',
            Path: SVGSource.VideoCam
        },
        MailOutline: {
            Name: 'MailOutline',
            Path: SVGSource.MailOutline
        },
        WarningOutline: {
            Name: 'WarningOutline',
            Path: SVGSource.WarningOutline
        },
        FileOutline: {
            Name: 'FileOutline',
            Path: SVGSource.FileOutline
        },
        FileEditOutline: {
            Name: 'FileEditOutline',
            Path: SVGSource.FileEditOutline
        },
        FileRequestOutline: {
            Name: 'FileRequestOutline',
            Path: SVGSource.FileRequestOutline
        },
        FilePDF: {
            Name: 'FilePDF',
            Path: SVGSource.FilePDF
        },
        FileAddOutline: {
            Name: 'FileAddOutline',
            Path: SVGSource.FileAddOutline
        },
        GraudationCapOutline: {
            Name: 'GraudationCapOutline',
            Path: SVGSource.GraudationCapOutline
        },
        KeyOutline: {
            Name: 'KeyOutline',
            Path: SVGSource.KeyOutline
        },
        LockOutline: {
            Name: 'LockOutline',
            Path: SVGSource.LockOutline
        },
        LocationOutline: {
            Name: 'LocationOutline',
            Path: SVGSource.LocationOutline
        },
        PaymentsOutline: {
            Name: 'PaymentsOutline',
            Path: SVGSource.PaymentsOutline
        },
        AttachFile: {
            Name: 'AttachFile',
            Path: SVGSource.AttachFile
        },
        PhoneEnabledOutline: {
            Name: 'PhoneEnabledOutline',
            Path: SVGSource.PhoneEnabledOutline
        },
        OldPhoneOutline: {
            Name: 'OldPhoneOutline',
            Path: SVGSource.OldPhoneOutline
        },
        ReceiptOutline: {
            Name: 'ReceiptOutline',
            Path: SVGSource.ReceiptOutline
        },
        Redo: {
            Name: 'Redo',
            Path: SVGSource.Redo
        },
        Search: {
            Name: 'Search',
            Path: SVGSource.Search
        },
        StickyNoteOutlined: {
            Name: 'StickyNoteOutlined',
            Path: SVGSource.StickyNoteOutlined
        },
        Sync: {
            Name: 'Sync',
            Path: SVGSource.Sync
        },
        TicketsOutline: {
            Name: 'TicketsOutline',
            Path: SVGSource.TicketsOutline
        },
        Undo: {
            Name: 'Undo',
            Path: SVGSource.Undo
        },
        GroupsOutline: {
            Name: 'GroupsOutline',
            Path: SVGSource.GroupsOutline
        },
        EditSquare: {
            Name: 'EditSquare',
            Path: SVGSource.EditSquare
        },
        MoreHoriz: {
            Name: 'MoreHoriz',
            Path: SVGSource.MoreHoriz
        },
        MoreVert: {
            Name: 'MoreVert',
            Path: SVGSource.MoreVert
        },
        MarkEmailReadOutline: {
            Name: 'MarkEmailReadOutline',
            Path: SVGSource.MarkEmailReadOutline
        },
        MailOpenedOutline: {
            Name: 'MailOpenedOutline',
            Path: SVGSource.MailOpenedOutline
        },
        MailMoney: {
            Name: 'MailMoney',
            Path: SVGSource.MailMoney
        },
        SyncAlt: {
            Name: 'SyncAlt',
            Path: SVGSource.SyncAlt
        },
        ErrorOutline: {
            Name: 'ErrorOutline',
            Path: SVGSource.ErrorOutline
        },
        Visibility: {
            Name: 'Visibility',
            Path: SVGSource.Visibility
        },
        VisibilityOutline: {
            Name: 'VisibilityOutline',
            Path: SVGSource.VisibilityOutline
        },
        VisibilityOffOutline: {
            Name: 'VisibilityOffOutline',
            Path: SVGSource.VisibilityOffOutline
        },
        SentimentSatisfiedOutline: {
            Name: 'SentimentSatisfiedOutline',
            Path: SVGSource.SentimentSatisfiedOutline
        },
        FileDescriptionOutline: {
            Name: 'FileDescriptionOutline',
            Path: SVGSource.FileDescriptionOutline
        },
        FileArchived: {
            Name: 'FileArchived',
            Path: SVGSource.FileArchived
        },
        AudioFileOutline: {
            Name: 'AudioFileOutline',
            Path: SVGSource.AudioFileOutline
        },
        FileCSV: {
            Name: 'FileCSV',
            Path: SVGSource.FileCSV
        },
        FileImage: {
            Name: 'FileImage',
            Path: SVGSource.FileImage
        },
        VideoFileOutline: {
            Name: 'VideoFileOutline',
            Path: SVGSource.VideoFileOutline
        },
        FolderOpen: {
            Name: 'FolderOpen',
            Path: SVGSource.FolderOpen
        },
        FolderSettings: {
            Name: 'FolderSettings',
            Path: SVGSource.FolderSettings
        },
        CreateNewFolderOutline: {
            Name: 'CreateNewFolderOutline',
            Path: SVGSource.CreateNewFolderOutline
        },
        GiftOutline: {
            Name: 'GiftOutline',
            Path: SVGSource.GiftOutline
        },
        Language: {
            Name: 'Language',
            Path: SVGSource.Language
        },
        GraduationCapOutline: {
            Name: 'GraduationCapOutline',
            Path: SVGSource.GraduationCapOutline
        },
        HoldBoxOutline: {
            Name: 'HoldBoxOutline',
            Path: SVGSource.HoldBoxOutline
        },
        HoldCashOutline: {
            Name: 'HoldCashOutline',
            Path: SVGSource.HoldCashOutline
        },
        HeartOutline: {
            Name: 'HeartOutline',
            Path: SVGSource.HeartOutline
        },
        History: {
            Name: 'History',
            Path: SVGSource.History
        },
        HomeOutline: {
            Name: 'HomeOutline',
            Path: SVGSource.HomeOutline
        },
        HourglassBottom: {
            Name: 'HourglassBottom',
            Path: SVGSource.HourglassBottom
        },
        ImageOutline: {
            Name: 'ImageOutline',
            Path: SVGSource.ImageOutline
        },
        ImageCollectionOutline: {
            Name: 'ImageCollectionOutline',
            Path: SVGSource.ImageCollectionOutline
        },
        OutboxOutline: {
            Name: 'OutboxOutline',
            Path: SVGSource.OutboxOutline
        },
        InfoOutline: {
            Name: 'InfoOutline',
            Path: SVGSource.InfoOutline
        },
        KeyboardOutline: {
            Name: 'KeyboardOutline',
            Path: SVGSource.KeyboardOutline
        },
        Laptop: {
            Name: 'Laptop',
            Path: SVGSource.Laptop
        },
        StackOutline: {
            Name: 'StackOutline',
            Path: SVGSource.StackOutline
        },
        LightbulbOutline: {
            Name: 'LightbulbOutline',
            Path: SVGSource.LightbulbOutline
        },
        Link: {
            Name: 'Link',
            Path: SVGSource.Link
        },
        ListAlt: {
            Name: 'ListAlt',
            Path: SVGSource.ListAlt
        },
        MicNone: {
            Name: 'MicNone',
            Path: SVGSource.MicNone
        },
        FileCheck: {
            Name: 'FileCheck',
            Path: SVGSource.FileCheck
        },
        FileInfo: {
            Name: 'FileInfo',
            Path: SVGSource.FileInfo
        },
        Smartphone: {
            Name: 'Smartphone',
            Path: SVGSource.Smartphone
        },
        CheckBook: {
            Name: 'CheckBook',
            Path: SVGSource.CheckBook
        },
        MusicNoteDoubleOutline: {
            Name: 'MusicNoteDoubleOutline',
            Path: SVGSource.MusicNoteDoubleOutline
        },
        MusicNoteOutline: {
            Name: 'MusicNoteOutline',
            Path: SVGSource.MusicNoteOutline
        },
        NewsPaper: {
            Name: 'NewsPaper',
            Path: SVGSource.NewsPaper
        },
        SendOutline: {
            Name: 'SendOutline',
            Path: SVGSource.SendOutline
        },
        CelebrationOutline: {
            Name: 'CelebrationOutline',
            Path: SVGSource.CelebrationOutline
        },
        EditOutline: {
            Name: 'EditOutline',
            Path: SVGSource.EditOutline
        },
        PeopleDistance: {
            Name: 'PeopleDistance',
            Path: SVGSource.PeopleDistance
        },
        PhoneAltOutline: {
            Name: 'PhoneAltOutline',
            Path: SVGSource.PhoneAltOutline
        },
        Deskphone: {
            Name: 'Deskphone',
            Path: SVGSource.Deskphone
        },
        PhotoVideoOutline: {
            Name: 'PhotoVideoOutline',
            Path: SVGSource.PhotoVideoOutline
        },
        Piano: {
            Name: 'Piano',
            Path: SVGSource.Piano
        },
        AirPlane: {
            Name: 'AirPlane',
            Path: SVGSource.AirPlane
        },
        PlayOutline: {
            Name: 'PlayOutline',
            Path: SVGSource.PlayOutline
        },
        Add: {
            Name: 'Add',
            Path: SVGSource.Add,
        },
        PrintOutline: {
            Name: 'PrintOutline',
            Path: SVGSource.PrintOutline
        },
        Print: {
            Name: 'Print',
            Path: SVGSource.Print
        },
        HelpOutline: {
            Name: 'HelpOutline',
            Path: SVGSource.HelpOutline
        },
        FormatQuote: {
            Name: 'FormatQuote',
            Path: SVGSource.FormatQuote
        },
        Shuffle: {
            Name: 'Shuffle',
            Path: SVGSource.Shuffle
        },
        CollectionCircleAdd: {
            Name: 'CollectionCircleAdd',
            Path: SVGSource.CollectionCircleAdd
        },
        Recycling: {
            Name: 'Recycling',
            Path: SVGSource.Recycling
        },
        Repeat: {
            Name: 'Repeat',
            Path: SVGSource.Repeat
        },
        Route: {
            Name: 'Route',
            Path: SVGSource.Route
        },
        DirectionsRun: {
            Name: 'DirectionsRun',
            Path: SVGSource.DirectionsRun
        },
        School: {
            Name: 'School',
            Path: SVGSource.School
        },
        Construction: {
            Name: 'Construction',
            Path: SVGSource.Construction
        },
        ZoomIn: {
            Name: 'ZoomIn',
            Path: SVGSource.ZoomIn
        },
        ShareInter: {
            Name: 'ShareInter',
            Path: SVGSource.ShareInter
        },
        Logout: {
            Name: 'Logout',
            Path: SVGSource.Logout
        },
        Sort: {
            Name: 'Sort',
            Path: SVGSource.Sort
        },
        SportsFootballOutline: {
            Name: 'SportsFootballOutline',
            Path: SVGSource.SportsFootballOutline
        },
        StarOutline: {
            Name: 'StarOutline',
            Path: SVGSource.StarOutline
        },
        Table: {
            Name: 'Table',
            Path: SVGSource.Table
        },
        OdometerOutline: {
            Name: 'OdometerOutline',
            Path: SVGSource.OdometerOutline
        },
        LabelTagOutline: {
            Name: 'LabelTagOutline',
            Path: SVGSource.LabelTagOutline
        },
        LabelTag: {
            Name: 'LabelTag',
            Path: SVGSource.LabelTag
        },
        DiscountTagOutline: {
            Name: 'DiscountTagOutline',
            Path: SVGSource.DiscountTagOutline
        },
        Checklist: {
            Name: 'Checklist',
            Path: SVGSource.Checklist
        },
        ThumbUpOutline: {
            Name: 'ThumbUpOutline',
            Path: SVGSource.ThumbUpOutline
        },
        KeepOutline: {
            Name: 'KeepOutline',
            Path: SVGSource.KeepOutline
        },
        TicketOutline: {
            Name: 'TicketOutline',
            Path: SVGSource.TicketOutline
        },
        CloseCircleOutline: {
            Name: 'CloseCircleOutline',
            Path: SVGSource.CloseCircleOutline
        },
        DeleteOutline: {
            Name: 'DeleteOutline',
            Path: SVGSource.DeleteOutline
        },
        LinkOff: {
            Name: 'LinkOff',
            Path: SVGSource.LinkOff
        },
        PersonOutline: {
            Name: 'PersonOutline',
            Path: SVGSource.PersonOutline,
        },
        PersonSettingsOutline: {
            Name: 'PersonSettingsOutline',
            Path: SVGSource.PersonSettingsOutline
        },
        PersonTimeOutline: {
            Name: 'PersonTimeOutline',
            Path: SVGSource.PersonTimeOutline
        },
        PersonEditOutline: {
            Name: 'PersonEditOutline',
            Path: SVGSource.PersonEditOutline
        },
        GroupOutline: {
            Name: 'GroupOutline',
            Path: SVGSource.GroupOutline
        },
        PersonArtistOutline: {
            Name: 'PersonArtistOutline',
            Path: SVGSource.PersonArtistOutline
        },
        PersonAddOutline: {
            Name: 'PersonAddOutline',
            Path: SVGSource.PersonAddOutline
        },
        VideoCamOutline: {
            Name: 'VideoCamOutline',
            Path: SVGSource.VideoCamOutline
        },
        WifiOff: {
            Name: 'WifiOff',
            Path: SVGSource.WifiOff
        },
        WindowIOS: {
            Name: 'WindowIOS',
            Path: SVGSource.WindowIOS
        },
        WindowWindows: {
            Name: 'WindowWindows',
            Path: SVGSource.WindowWindows
        },
        Mail: {
            Name: 'Mail',
            Path: SVGSource.Mail
        },
        MailOpened: {
            Name: 'MailOpened',
            Path: SVGSource.MailOpened
        },
        Warning: {
            Name: 'Warning',
            Path: SVGSource.Warning
        },
        FileDescription: {
            Name: 'FileDescription',
            Path: SVGSource.FileDescription
        },
        FileAdd: {
            Name: 'FileAdd',
            Path: SVGSource.FileAdd
        },
        Folder: {
            Name: 'Folder',
            Path: SVGSource.Folder
        },
        Gift: {
            Name: 'Gift',
            Path: SVGSource.Gift
        },
        DragIndicator: {
            Name: 'DragIndicator',
            Path: SVGSource.DragIndicator
        },
        Heart: {
            Name: 'Heart',
            Path: SVGSource.Heart
        },
        Image: {
            Name: 'Image',
            Path: SVGSource.Image
        },
        Lightbulb: {
            Name: 'Lightbulb',
            Path: SVGSource.Lightbulb
        },
        Location: {
            Name: 'Location',
            Path: SVGSource.Location
        },
        Mic: {
            Name: 'Mic',
            Path: SVGSource.Mic
        },
        Payments: {
            Name: 'Payments',
            Path: SVGSource.Payments
        },
        MusicNoteDouble: {
            Name: 'MusicNoteDouble',
            Path: SVGSource.MusicNoteDouble
        },
        Newspaper: {
            Name: 'Newspaper',
            Path: SVGSource.Newspaper
        },
        Celebration: {
            Name: 'Celebration',
            Path: SVGSource.Celebration
        },
        Airplane: {
            Name: 'Airplane',
            Path: SVGSource.Airplane
        },
        Help: {
            Name: 'Help',
            Path: SVGSource.Help
        },
        Reply: {
            Name: 'Reply',
            Path: SVGSource.Reply
        },
        SentimentSatisfied: {
            Name: 'SentimentSatisfied',
            Path: SVGSource.SentimentSatisfied
        },
        Odometer: {
            Name: 'Odometer',
            Path: SVGSource.Odometer
        },
        ThumbUp: {
            Name: 'ThumbUp',
            Path: SVGSource.ThumbUp
        },
        Delete: {
            Name: 'Delete',
            Path: SVGSource.Delete
        },
        PersonTime: {
            Name: 'PersonTime',
            Path: SVGSource.PersonTime
        },
        PersonSettings: {
            Name: 'PersonSettings',
            Path: SVGSource.PersonSettings
        },
        PersonAdd: {
            Name: 'PersonAdd',
            Path: SVGSource.PersonAdd
        },
        PersonVoiceOver: {
            Name: 'PersonVoiceOver',
            Path: SVGSource.PersonVoiceOver,
        },
        Info: {
            Name: 'Info',
            Path: SVGSource.Info
        },
        PersonCheck: {
            Name: 'PersonCheck',
            Path: SVGSource.PersonCheck
        },
        TimerOutline: {
            Name: 'TimerOutline',
            Path: SVGSource.TimerOutline
        },
        AddCircleOutline: {
            Name: 'AddCircleOutline',
            Path: SVGSource.AddCircleOutline
        },
        Numbers: {
            Name: 'Numbers',
            Path: SVGSource.Numbers
        },
        QueueMusic: {
            Name: 'QueueMusic',
            Path: SVGSource.QueueMusic,
        },
        MusicNoteAdd: {
            Name: 'MusicNoteAdd',
            Path: SVGSource.MusicNoteAdd,
        },
        PersonCheckAlt: {
            Name: 'PersonCheckAlt',
            Path: SVGSource.PersonCheckAlt,
        },
        EditNote: {
            Name: 'EditNote',
            Path: SVGSource.EditNote,
        },
        AddTime: {
            Name: 'AddTime',
            Path: SVGSource.AddTime,
        },
        AutoRenew: {
            Name: 'AutoRenew',
            Path: SVGSource.AutoRenew
        },
        ArchiveOutline: {
            Name: 'ArchiveOutline',
            Path: SVGSource.ArchiveOutline
        },
        UnarchiveOutline: {
            Name: 'UnarchiveOutline',
            Path: SVGSource.UnarchiveOutline
        },
    },
    Attendance: {
        Absent: {
            Name: 'Absent',
            Path: SVGSource.AttendanceAbsent
        },
        AbsentGiveMakeup: {
            Name: 'AbsentGiveMakeup',
            Path: SVGSource.AttendanceAbsentGiveMakeup
        },
        AbsentUseMakeup: {
            Name: 'AbsentUseMakeup',
            Path: SVGSource.AttendanceAbsentUseMakeup
        },
        AbsentNotice: {
            Name: 'AbsentNotice',
            Path: SVGSource.AttendanceAbsentNotice
        },
        Cancel: {
            Name: 'Cancel',
            Path: SVGSource.AttendanceCancel
        },
        CancelMakeup: {
            Name: 'CancelMakeup',
            Path: SVGSource.AttendanceCancelMakeup
        },
        Present: {
            Name: 'Present',
            Path: SVGSource.AttendancePresent
        },
        PresentLate: {
            Name: 'PresentLate',
            Path: SVGSource.AttendancePresentLate
        },
        PresentLateUseMakeup: {
            Name: 'PresentLateUseMakeup',
            Path: SVGSource.AttendancePresentLateUseMakeup
        },
        PresentUseMakeup: {
            Name: 'PresentUseMakeup',
            Path: SVGSource.AttendancePresentUseMakeup
        },
        Unrecorded: {
            Name: 'Unrecorded',
            Path: SVGSource.AttendanceUnrecorded
        },
        TeacherCanceled: {
            Name: 'TeacherCanceled',
            Path: SVGSource.AttendanceTeacherCanceled
        },
        TeacherCanceledMakeup: {
            Name: 'TeacherCanceledMakeup',
            Path: SVGSource.AttendanceTeacherCanceledMakeup
        },
        StickyNoteAdd: {
            Name: 'StickyNoteAdd',
            Path: SVGSource.StickyNoteAdd
        },
        StickyNoteFilled: {
            Name: 'StickyNoteFilled',
            Path: SVGSource.StickyNoteFilled
        },
        StickyNoteGroup: {
            Name: 'StickyNoteGroup',
            Path: SVGSource.StickyNoteGroup,
        },
        CheckAll: {
            Name: 'CheckAll',
            Path: SVGSource.CheckAll,
        },
    },
    Calendar: {
        CalendarMonth: {
            Name: 'CalendarMonth',
            Path: SVGSource.CalendarMonth,
        },
        CalendarToday: {
            Name: 'CalendarToday',
            Path: SVGSource.CalendarToday,
        },
        CalendarRemove: {
            Name: 'CalendarRemove',
            Path: SVGSource.CalendarRemove
        },
        CalendarEdit: {
            Name: 'CalendarEdit',
            Path: SVGSource.CalendarEdit
        },
        CalendarCheck: {
            Name: 'CalendarCheck',
            Path: SVGSource.CalendarCheck
        },
        CalendarAdd: {
            Name: 'CalendarAdd',
            Path: SVGSource.CalendarAdd
        },
        CalendarCancel: {
            Name: 'CalendarCancel',
            Path: SVGSource.CalendarCancel
        },
        CalendarViewMonth: {
            Name: 'CalendarViewMonth',
            Path: SVGSource.CalendarViewMonth
        },
        CalendarStar: {
            Name: 'CalendarStar',
            Path: SVGSource.CalendarStar
        },
    },
    Brand: {
        Apple: {
            Name: 'Apple',
            Path: SVGSource.Apple
        },
        FacebookCircle: {
            Name: 'FacebookCircle',
            Path: SVGSource.FacebookCircle
        },
        Google: {
            Name: 'Google',
            Path: SVGSource.Google
        },
        InstagramOutlined: {
            Name: 'InstagramOutlined',
            Path: SVGSource.InstagramOutlined
        },
        Linkedin: {
            Name: 'Linkedin',
            Path: SVGSource.Linkedin
        },
        Skype: {
            Name: 'Skype',
            Path: SVGSource.Skype
        },
        TikTok: {
            Name: 'TikTok',
            Path: SVGSource.TikTok
        },
        Twitter: {
            Name: 'Twitter',
            Path: SVGSource.Twitter
        },
        Windows: {
            Name: 'Windows',
            Path: SVGSource.Windows
        },
        XCorp: {
            Name: 'XCorp',
            Path: SVGSource.XCorp
        },
        Youtube: {
            Name: 'Youtube',
            Path: SVGSource.Youtube
        }
    },
    Video: {
        VolumeOff: {
            Name: 'VolumeOff',
            Path: SVGSource.VolumeOff
        },
        VolumeUp: {
            Name: 'VolumeUp',
            Path: SVGSource.VolumeUp
        },
        VolumeMute: {
            Name: 'VolumeMute',
            Path: SVGSource.VolumeMute
        },
        VolumeDown: {
            Name: 'VolumeDown',
            Path: SVGSource.VolumeDown
        },
        Play: {
            Name: 'Play',
            Path: SVGSource.Play
        },
        Pause: {
            Name: 'Pause',
            Path: SVGSource.Pause
        },
    },
    Payment: {
        CreditCard: {
            Name: 'CreditCard',
            Path: SVGSource.CreditCard
        },
        StripeSingle: {
            Name: 'StripeSingle',
            Path: SVGSource.StripeSingle
        },
        PayPalSingle: {
            Name: 'PayPalSingle',
            Path: SVGSource.PayPalSingle
        },
        CCAmex: {
            Name: 'CCAmex',
            Path: SVGSource.CCAmex
        },
        CCDinersClub: {
            Name: 'CCDinersClub',
            Path: SVGSource.CCDinersClub
        },
        CCDiscover: {
            Name: 'CCDiscover',
            Path: SVGSource.CCDiscover
        },
        CCJCB: {
            Name: 'CCJCB',
            Path: SVGSource.CCJCB
        },
        CCMasterCard: {
            Name: 'CCMasterCard',
            Path: SVGSource.CCMasterCard
        },
        CCPayPal: {
            Name: 'CCPayPal',
            Path: SVGSource.CCPayPal,
        },
        CCStripe: {
            Name: 'CCStripe',
            Path: SVGSource.CCStripe,
        },
        CCVisa: {
            Name: 'CCVisa',
            Path: SVGSource.CCVisa
        },
        PayPal: {
            Name: 'PayPal',
            Path: SVGSource.PayPal
        },
        PayPalText: {
            Name: 'PayPalText',
            Path: SVGSource.PayPalText
        },
        VisaLight: {
            Name: 'VisaLight',
            Path: SVGSource.VisaLight
        },
        VisaDark: {
            Name: 'VisaDark',
            Path: SVGSource.VisaDark
        },
        MasterCardLight: {
            Name: 'MasterCardLight',
            Path: SVGSource.MasterCardLight
        },
        MasterCardDark: {
            Name: 'MasterCardDark',
            Path: SVGSource.MasterCardDark
        },
        Amex: {
            Name: 'Amex',
            Path: SVGSource.Amex
        },
        DinersClub: {
            Name: 'DinersClub',
            Path: SVGSource.DinersClub
        },
        Maestro: {
            Name: 'Maestro',
            Path: SVGSource.Maestro
        },
        Discover: {
            Name: 'Discover',
            Path: SVGSource.Discover
        },
        DiscoverColour: {
            Name: 'DiscoverColour',
            Path: SVGSource.DiscoverColour
        },
        JCB: {
            Name: 'JCB',
            Path: SVGSource.JCB
        },
        Venmo: {
            Name: 'Venmo',
            Path: SVGSource.Venmo
        },
        Savings: {
            Name: 'Savings',
            Path: SVGSource.Savings
        }
    }
}


export type Icon = typeof Icon;
