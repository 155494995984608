import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { AttachmentModule } from '@library/attachment';
import { ButtonModule } from '@library/button';
import { IconModule } from '@library/icon';
import { LayoutModule } from '@library/layout';
import { UploadModule } from '@library/upload';
import { DialogConfirmComponent } from './confirm/dialog-confirm.component';
import { DialogDeleteComponent } from './delete/dialog-delete.component';
import { DialogInfoComponent } from './info/dialog-info.component';
import { DialogRecurringDeleteComponent } from './recurring-delete/dialog-recurring-delete.component';
import { FormFieldModule } from '@library/form-field';
import { UtilityModule } from '@library/utility';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogIFrameComponent } from './iframe/dialog-iframe.component';
import { IframeModule } from '@library/iframe';
import { DialogProgressComponent } from './progress/dialog-progress.component';
import { ProgressBarModule } from '@library/progress-bar';

@NgModule({
    declarations: [
        DialogProgressComponent,
        DialogConfirmComponent,
        DialogDeleteComponent,
        DialogInfoComponent,
        DialogRecurringDeleteComponent,
        DialogIFrameComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        MatDialogModule,
        IconModule,
        MatDividerModule,
        AttachmentModule,
        LayoutModule,
        UploadModule,
        FormFieldModule,
        ProgressBarModule,
        UtilityModule,
        ReactiveFormsModule,
        IframeModule
    ],
    exports: [
        DialogProgressComponent,
        DialogConfirmComponent,
        DialogDeleteComponent,
        DialogInfoComponent,
    ],
})
export class DialogModule {}
