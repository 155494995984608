<lib-card class="scss-signup-form-card">
    <form [formGroup]="addStudentForm">
        <div class="single-column-row-website-form" *ngIf="data.AllowedStudentTypes === StudentTypes.ChildOrAdult">
            <lib-radio-group [formControl]="addStudentForm.controls.StudentType"
                [orientation]="Orientation.Horizontal" (change)="StudentTypeChanged($event)">
                <ng-container label i18n="@@SUFAllowedStudentTypesLabel">
                    #Student# Type
                </ng-container>
                <lib-radio-group-item
                    [value]="StudentSignupType.AdultStudent">
                        <p class="input-typography" i18n="@@SUFAddStudentAdultRadioOption">Adult</p>
                </lib-radio-group-item>
                <lib-radio-group-item
                    [value]="StudentSignupType.ChildStudent">
                        <p class="input-typography" i18n="@@SUFAddStudentChildRadioOption">Child</p>
                </lib-radio-group-item>
            </lib-radio-group>
        </div>

        <!-- DEFAULT -->
        <div class="two-column-row-website-form">

            <lib-input-text
                [formControl]="addStudentForm.controls.FirstName"
                [maxCharacterLength]="60"
                [errorMessages]="firstNameError">
                <ng-container label i18n="@@SUFDesignBlockFirstName">
                    First Name
                </ng-container>
            </lib-input-text>

            <lib-input-text
                [formControl]="addStudentForm.controls.LastName"
                [maxCharacterLength]="60"
                [errorMessages]="lastNameError">
                <ng-container label i18n="@@SUFDesignBlockLastNayyme">
                    Last Name
                </ng-container>
            </lib-input-text>
        </div>

        <div class="single-column-row-website-form">
            <lib-input-text [formControl]="addStudentForm.controls.Email"
                [maxCharacterLength]="190"
                [errorMessages]="emailError">
                <ng-container label i18n="@@SUFDesignBlockEmail">
                    Email
                </ng-container>
            </lib-input-text>
        </div>

        <div *ngIf="showStudentExistsWarning" class="mb-4 scss-student-exists-banner">
            <lib-banner [kind]="StatusKind.Warning" [isDismissable]="false">
                <ng-container title i18n="@@SUFDesignBlockStudentExistsBannerTitle">This #student# already exists</ng-container>
                <ng-container text i18n="@@SUFDesignBlockStudentAlreadyExistsBannerText">You have already entered a #student# under this name. Please try editing your existing entry.</ng-container>
            </lib-banner>
        </div>

        <!-- ADDITIONAL FIELDS -->
        <div class="two-column-row-website-form" >

            <lib-input-text *ngIf="data.CollectGender"
            [formControl]="addStudentForm.controls.Gender">
                <ng-container label i18n="@@SUFDesignBlockGender">
                    Gender
                </ng-container>
            </lib-input-text>

            <lib-input-date  *ngIf="data.CollectBirthday"
                [formControl]="addStudentForm.controls.Birthday"
                placeholderText="Enter a date..."
                i18n-placeholderText="@@CommonEnterDatePlaceholder"
                [maxDate]="todaysDate"
                [minDate]="specialMinimalDate"
                [scrollLock]="false"
                >
                <ng-container label i18n="@@SUFSignupBlcokStep1LabelBirthday">
                    Birthday
                </ng-container>
            </lib-input-date>

            <lib-input-text *ngIf="data.CollectLocalSchool" [formControl]="addStudentForm.controls.LocalSchool" >
                <ng-container label i18n="@@SUFSignupBlcokStep1LabelLocalSchool">
                    Current School
                </ng-container>
            </lib-input-text>

            <lib-input-select *ngIf="data.CollectSubjectType && renderContext.SignupFormRenderContext!.Subjects.length > 0"
                [formControl]="addStudentForm.controls.Subject"
                placeholderText="#Subject#"
                i18n-placeholderText="@@SUFSignupBlcokStep1PlaceholderSubject"
                (change)="SubjectInstrumentChanged($event)">
                <ng-container label i18n="@@SUFSignupBlcokStep1LabelSubject">
                    #Subject#
                </ng-container>
                <ng-container *ngFor="let subject of renderContext.SignupFormRenderContext?.Subjects">
                    <lib-input-select-item
                        [value]="subject">
                        {{subject}}
                    </lib-input-select-item>
                </ng-container>
            </lib-input-select>

            <!-- NGIF Multi Teacher school ?? delta -->
            <lib-input-select *ngIf="data.CollectPreferredTeacher && renderContext.SignupFormRenderContext!.PreferredTeachers.length > 0"
                [formControl]="addStudentForm.controls.PreferredTeacherID"
                [sortBy]="null"
                placeholderText="Preferred #Teacher#"
                i18n-placeholderText="@@SUFDesignBlockPlaceholderPreferredTeacher">
                <ng-container label i18n="@@SUFDesignBlockPreferredTeacher">
                    Preferred #Teacher#
                </ng-container>
                <lib-input-select-item
                    [value]="noPreferredTeacher" i18n="@@SUFDesignBlockNoPreferredTeacher">
                    No Preferred #Teacher#
                </lib-input-select-item>
                <ng-container *ngFor="let teacher of preferredTeachers">
                    <lib-input-select-item
                        [value]="teacher.ID!">
                        {{teacher.Name}}
                    </lib-input-select-item>
                </ng-container>
            </lib-input-select>

            <lib-input-text *ngIf="data.CollectSubjectType && renderContext.SignupFormRenderContext!.Subjects.length === 0"
                [formControl]="addStudentForm.controls.Subject">
                <ng-container label i18n="@@SUFSignupBlcokStep1LabelSubject">
                    #Subject#
                </ng-container>
            </lib-input-text>

            <lib-input-text *ngIf="data.CollectSkillLevel" [formControl]="addStudentForm.controls.SkillLevel" >
                <ng-container label i18n="@@SUFDesignBlockSkillLevel">
                    Skill Level
                </ng-container>
            </lib-input-text>

            <lib-input-text *ngIf="data.CollectFaceTimeID" [formControl]="addStudentForm.controls.FaceTimeID" >
                <ng-container label i18n="@@SUFDesignBlockFaceTimeID">
                    FaceTime ID
                </ng-container>
            </lib-input-text>

            <lib-input-text *ngIf="data.CollectSkypeUsername" [formControl]="addStudentForm.controls.SkypeUsername" >
                <ng-container label i18n="@@SUFDesignBlockSkypeUsername">
                    Skype Username
                </ng-container>
            </lib-input-text>

        </div>

        <lib-signup-form-custom-fields
            [kind]="FormAffinity.Student"
            [fields]="data.CustomFields"
            [form]="addStudentForm"
            [isViewOnly]="isViewOnly">
        </lib-signup-form-custom-fields>

    </form>
    <lib-button *ngIf="students.length > 0"
        class="float-end"
        [kind]="ButtonKind.DangerSecondary"
        (clicked)="DeleteStudentClicked()">
        <ng-container label i18n="@@SUFDesignBlockRemoveStudentButton">Remove #Student#</ng-container>
    </lib-button>
</lib-card>

<div class="py-2 cursor-pointer" *ngFor="let student of students">
    <lib-card  (click)="EditStudent(student)">
        <h5 class="brand-500">{{student.FirstName}} {{student.LastName}}</h5>
    </lib-card>
</div>

<div class="pt-2" [ngClass]="{
    'd-flex flex-row w-100 justify-content-end gap-2': !isMobile
    }">

    <div *ngIf="students.length < (MAX_STUDENTS - 1)"
    [ngClass]="{
        'mb-2': isMobile
        }">
        <lib-button
            class="scss-signupform-outline-secondary-btn-accent-color"
            [kind]="ButtonKind.Secondary"
            [size]="isMobile ? ButtonSize.FullWidth : ButtonSize.Medium"
            [disabled]="verifyingStudent"
            [isViewOnly]="isViewOnly"
            (clicked)="AddAnotherStudentClicked()">
            <ng-container label i18n="@@SUFAddAnotherStudent">Add Another #Student#</ng-container>
        </lib-button>
    </div>

    <div>
        <lib-button
            class="scss-widget-primary-btn-accent-color"
            [kind]="ButtonKind.Primary"
            [size]="isMobile ? ButtonSize.FullWidth : ButtonSize.Medium"
            [loading]="verifyingStudent"
            [disabled]="verifyingStudent"
            [isViewOnly]="isViewOnly"
            (clicked)="NextClicked()">
            <ng-container label>
                <ng-container i18n="@@CommonNextButton">Next</ng-container>
            </ng-container>
        </lib-button>
    </div>
</div>
