import { DateFormat, SBDateTime } from './sb-date-time';

export class SBDate extends SBDateTime {

    public static FromComponents(year: number, month: number, day: number = 1): SBDate {
        const monthIndex = month - 1;
        const date = new Date(year, monthIndex, day);
        if (!date || year < 1850 || date.getDate() != day || date.getMonth() != monthIndex) {
            throw new Error('Invalid date');
        }

        return new SBDate(date);
    }

    // Parsing

    public static Parse(dateString: string, format: DateFormat = 'short'): SBDate {
        switch (format) {
            case 'short': return SBDate.ParseShort(dateString);
            case 'iso': return SBDate.FromISO(dateString);
            default: throw new Error(`Parsing of format ${format} is unimplemented`);
        }
    }

    private static ParseShort(dateString: string): SBDate { 
        const parsed = dateString.trim().replace(SBDate._dateSeparatorRegExp, ' ').split(' ');

        if (parsed.length != 3) {
            throw new Error('Invalid date');
        }
        
        const parsedYear = parsed[SBDate._yearIndex];
        if (parsedYear.length < 2) {
            throw new Error('Invalid date');
        }

        let year = Number(parsedYear);
        if (year <= 50) {
            year += 2000;
        } else if (year < 100) {
            year += 1900;
        }

        const month = Number(parsed[SBDate._monthIndex]);
        if (month < 0 || month > 12){
            throw new Error('Invalid date');
        }
        const day = Number(parsed[SBDate._dayIndex]);
        const dateCheck = SBDate.FromComponents(year, month, 1);
        if (day < 0 || day > dateCheck.numberOfDaysInMonth){
            throw new Error('Invalid date');
        }

        return SBDate.FromComponents(year, month, day);
    }

    // Rendering

    public Render(format: DateFormat = 'short'): string {
        switch (format) {
            case 'short': return this.RenderForFormatString(SBDate._shortDatePattern);
            case 'long': return this.RenderForFormatString(SBDate._longDatePattern);
            case 'monthday': return this.RenderForFormatString(SBDate._monthDayPattern);
            case 'yearmonth': return this.RenderForFormatString(SBDate._yearMonthPattern);
            case 'dayofweek': return this.RenderForFormatString(SBDate._dayOfWeekPattern);
            case 'iso': return this.ToISO();
            default: throw new Error(`Parsing of format ${format} is unimplemented`);
        }
    }

    private RenderForFormatString(formatString: string): string {
        return formatString.replace(SBDate._dateRenderRegExp, match => {
            if (match[0] == "'") {
                return match.slice(1, -1);
            } else {
                return SBDate._dateRenderMapping[match](this);
            }
        });
    }

    public override ToISO(): string {
        return this.dateWithoutTimezone.toISOString().slice(0, 10);
    }

    public override Clone(): SBDate {
        return new SBDate(new Date(this._date));
    }

    // Convenience methods

    public static Today(): SBDate {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return new SBDate(date);
    }

    public static StartOfMonth(): SBDate {
        const date = new Date();
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        return new SBDate(date);
    }

    public static EndOfMonth(): SBDate {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        date.setHours(0, 0, 0, 0);
        return new SBDate(date);
    }

    public static override FromISO(dateString: string): SBDate {
        const date = SBDateTime.ISOtoJSDate(dateString);
        date.setHours(0, 0, 0, 0);
        
        return new SBDate(date);
    }

    public static override FromJSDate(jsDate: Date): SBDate {
        const date = new Date(jsDate);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid JS date');
        }

        date.setHours(0, 0, 0, 0);
        return new SBDate(date);
    }

    public static override FromMilliseconds(milliseconds: number): SBDate {
        const date = new Date(milliseconds);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid date milliseconds');
        }
        
        date.setHours(0, 0, 0, 0);
        return new SBDate(date);
    }

    public static FromSBDateTime(dateTime: SBDateTime): SBDate {
        const date = dateTime.ToJSDate();

        date.setHours(0, 0, 0, 0);
        return new SBDate(date);
    }

    public static MaxSBDate(dates: Array<SBDate>): SBDate {
      return dates.reduce(function (a, b) {
        return a.totalMilliseconds > b.totalMilliseconds ? a : b;
      });
    }

    public static MinSBDate(dates: Array<SBDate>): SBDate {
      return dates.reduce(function (a, b) {
        return a.totalMilliseconds < b.totalMilliseconds ? a : b;
      }); 
    }
}