import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AbstractType, AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { BaseViewComponent, FieldOf } from '@library/base';
import { startWith } from 'rxjs';
import { DragAndDropItemComponent } from './drag-and-drop-item.component';

type OrdererOf<T> = { [K in FieldOf<T>]: T[K] extends number ? K : never }[FieldOf<T>];

@Component({
    selector: 'lib-drag-and-drop',
    templateUrl: './drag-and-drop.component.html',
    styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent<T> extends BaseViewComponent implements OnInit, AfterContentInit {

    @ContentChildren(DragAndDropItemComponent) itemQueryList!: QueryList<DragAndDropItemComponent<T>>;

    private _sortedItemList!: DragAndDropItemComponent<T>[];

    private _orderBy?: OrdererOf<T>;
    @Input()
    public set orderBy(value: OrdererOf<T>) {
        this._orderBy = value;
    }

    @Input() type!: AbstractType<T>;
    @Input() disableDrag: boolean = false;

    @Output() reordered = new EventEmitter<Reordering<T>>();


    ngAfterContentInit(): void {
        this.itemQueryList.changes.pipe(startWith(this.itemQueryList)).subscribe(_ => {
            this.SortItems();
        });
    }

    private SortItems(): void {
        if (this._orderBy === undefined) {
            throw Error(`Could not determine a field to use as an order index when setting up (${this.constructor.name}).`);
        } else {
            this._sortedItemList = this.itemQueryList.toArray();
            this._sortedItemList.sort((x, y) => x.value[this._orderBy!] < y.value[this._orderBy!] ? -1 : 1);
        }
    }



    get sortedItemList(): DragAndDropItemComponent<T>[] {
        return this._sortedItemList;
    }

    Dropped(event: CdkDragDrop<string[]>) {
        if (event.currentIndex === event.previousIndex) {
            return;
        }

        this.reordered.emit(new Reordering<T>({
            Item: this._sortedItemList[event.previousIndex].value,
            ToOrderIndex: this._sortedItemList[event.currentIndex].value[this._orderBy!] as any
        }));

        moveItemInArray(this._sortedItemList, event.previousIndex, event.currentIndex);
    }
}

export class Reordering<T> {
    Item!: T;
    ToOrderIndex!: number;

    constructor(initializer: Reordering<T>) {
        Object.assign(this, initializer);
    }
}
