<lib-dialog-layout>
    <ng-container title>
        {{ data.Title }}
    </ng-container>
    <ng-container content>
        <p>{{ data.Text }}</p>
    </ng-container>
    <ng-container actions>

        <lib-button
            id="LibraryDialogSecondaryButton"
            [kind]="ButtonKind.Secondary"
            [size]="ButtonSize.Medium"
            [disabled]="primaryActionClicked"
            (clicked)="DismissAction()">
            <ng-container label>{{ data.SecondaryButtonText }}</ng-container>
        </lib-button>
        <lib-button
            id="LibraryDialogPrimaryButton"
            class="text-nowrap"
            [kind]="ButtonKind.Danger"
            [size]="ButtonSize.Medium"
            [loading]="primaryActionClicked"
            (clicked)="PrimaryAction()">
            <ng-container label>{{ data.PrimaryButtonText }}</ng-container>
    </lib-button>
    </ng-container>
</lib-dialog-layout>
