import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import { UtilityModule } from '@library/utility';
import { ButtonModule } from '@library/button';
import { DialogModule } from '@library/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UploadModule } from '@library/upload';
import { AvatarUploadDialogComponent } from './upload-dialog/avatar-upload-dialog.component';
import { LayoutModule } from '@library/layout';
import { FormFieldModule } from '@library/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ElevioModule } from '@library/elevio';


@NgModule({
    declarations: [
        AvatarComponent,
        AvatarUploadDialogComponent
    ],
    imports: [
        CommonModule,
        UtilityModule,
        ButtonModule,
        DialogModule,
        MatTooltipModule,
        UploadModule,
        LayoutModule,
        FormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        ElevioModule
    ],
    exports:[
        AvatarComponent,
        AvatarUploadDialogComponent
    ]
})
export class AvatarModule { }
