<div class="d-flex justify-content-center align-items-center px-2 status-label"
    [ngClass]="kind !== StatusKind.Custom ? kind : labelColor"
    [ngStyle]="{'cursor': clickable ? 'pointer' : 'inherit'}"
    [matTooltip]="toolTipText" matTooltipPosition="after"
    (click)="Clicked()">
    <div [ngClass]="{'align-items-center': showSpinner || icon}" class="d-flex justify-space-between gap-1" [attr.data-sb-qa]="qaTag">
        <lib-icon *ngIf="icon"
            [icon]="icon"
            [size]="IconSize.TwoExtraSmall">
        </lib-icon>
        <p class="typography-label-medium">
            <ng-content select="[label]"></ng-content>
        </p>
        <div *ngIf="showSpinner" class="spinner-border spinner-border-sm scss-status-label-spinner" role="status"></div>
    </div>

</div>
