import { LocalizationInfo } from "@library/data-models";

export type TimeFormat = 'short' | 'long' | 'iso';
export type DateFormat = 'short' | 'long' | 'monthday' | 'yearmonth' | 'dayofweek' | 'iso';

export class SBDateTime {

    // Static

    private static _isoRegex: RegExp = new RegExp('^(\\d{4})-(\\d{2})-(\\d{2})(?:T(\\d{2}):(\\d{2}):(\\d{2}).*)?$');

    // Date parsing/rendering
    protected static _shortDatePattern: string = '';
    protected static _longDatePattern: string = '';
    protected static _monthDayPattern: string = '';
    protected static _yearMonthPattern: string = '';
    protected static _dayOfWeekPattern: string = 'dddd';
    protected static _yearIndex: number = 0;
    protected static _monthIndex: number = 1;
    protected static _dayIndex: number = 2;
    protected static _dateSeparatorRegExp: RegExp = new RegExp('\\D+', 'g');

    protected static _dateRenderRegExp: RegExp = new RegExp("'[^']*'|y+|M+|d+", 'g');
    protected static _dateRenderMapping: { [x: string]: (d: SBDateTime) => string };

    // Time parsing/rendering
    protected static _is12Hour: boolean = false;
    protected static _amDesignator: string = 'am';
    protected static _pmDesignator: string = 'pm';
    protected static _shortTimePattern: string = '';
    protected static _longTimePattern: string = '';
    protected static _hourIndex: number = 1;
    protected static _minuteIndex: number = 2;
    protected static _meridianIndex: number = 3;
    protected static _timeRegExp: RegExp = /./;

    protected static _timeRenderRegExp = new RegExp("'[^']*'|H+|h+|m+|s+|t+", 'g');
    protected static _timeRenderMapping: { [x: string]: (d: SBDateTime) => string } = null as any;

    public static SetLocalizationInfo(localizationInfo: LocalizationInfo): void {
        // Build regexes based on localization info
        SBDateTime.InitializeDateParser(localizationInfo);
        SBDateTime.InitializeTimeParser(localizationInfo);
        SBDateTime.InitializeTimeRenderer(localizationInfo);
        SBDateTime.InitializeDateRenderer(localizationInfo);
    }

    private static InitializeDateParser(localizationInfo: LocalizationInfo): void {
        SBDateTime._shortDatePattern = localizationInfo.ShortDatePattern!;
        SBDateTime._longDatePattern = localizationInfo.LongDatePattern!;
        SBDateTime._monthDayPattern = localizationInfo.MonthDayPattern!;
        SBDateTime._yearMonthPattern = localizationInfo.YearMonthPattern!;

        const order = SBDateTime._shortDatePattern.replace(/y+/, '0').replace(/M+/, '1').replace(/d+/, '2').replace(/\D/g, '');

        SBDateTime._yearIndex = order.indexOf('0');
        SBDateTime._monthIndex = order.indexOf('1');
        SBDateTime._dayIndex = order.indexOf('2');
    }

    private static InitializeTimeParser(localizationInfo: LocalizationInfo): void {
        SBDateTime._is12Hour = localizationInfo.Is12Hour;
        SBDateTime._amDesignator = localizationInfo.AMDesignator!;
        SBDateTime._pmDesignator = localizationInfo.PMDesignator!;
        SBDateTime._shortTimePattern = localizationInfo.ShortTimePattern!;
        SBDateTime._longTimePattern = localizationInfo.LongTimePattern!;

        const meridianRegExp = `(${SBDateTime._amDesignator}|${SBDateTime._pmDesignator})?`;

        const order = localizationInfo.ShortTimePattern!.replace(/h+/i, '0').replace(/m+/, '1').replace(/t+/, '2').replace(/\D/g, '');
        SBDateTime._hourIndex = order.indexOf('0');
        SBDateTime._minuteIndex = order.indexOf('1');
        SBDateTime._meridianIndex = order.indexOf('2');

        let regExpBuilder: string[] = [];
        regExpBuilder[SBDateTime._hourIndex] = "(\\d{1,4})";
        regExpBuilder[SBDateTime._minuteIndex] = "(\\d{0,2})";
        if (SBDateTime._is12Hour) {
            regExpBuilder[SBDateTime._meridianIndex] = meridianRegExp;
        }

        const separators = `[^0-9${SBDateTime._amDesignator[0]}${SBDateTime._pmDesignator[0]}]*`;
        SBDateTime._timeRegExp = new RegExp(`^${regExpBuilder.join(separators)}\$`, 'i');
    }

    private static InitializeTimeRenderer(localizationInfo: LocalizationInfo): void {
        SBDateTime._timeRenderMapping = {
            H:  (dateTime: SBDateTime) => dateTime.hour.toString(),
            HH: (dateTime: SBDateTime) => dateTime.hour.toString().padStart(2, '0'),
            h:  (dateTime: SBDateTime) => ((dateTime.hour + 11) % 12 + 1).toString(),
            hh: (dateTime: SBDateTime) => ((dateTime.hour + 11) % 12 + 1).toString().padStart(2, '0'),
            m:  (dateTime: SBDateTime) => dateTime.minute.toString(),
            mm: (dateTime: SBDateTime) => dateTime.minute.toString().padStart(2, '0'),
            s:  (dateTime: SBDateTime) => dateTime.second.toString(),
            ss: (dateTime: SBDateTime) => dateTime.second.toString().padStart(2, '0'),
            t:  (dateTime: SBDateTime) => dateTime.hour < 12 ? SBDateTime._amDesignator[0] : SBDateTime._pmDesignator[0],
            tt: (dateTime: SBDateTime) => dateTime.hour < 12 ? SBDateTime._amDesignator : SBDateTime._pmDesignator
        };
    }

    private static InitializeDateRenderer(localizationInfo: LocalizationInfo): void {
        SBDateTime._dateRenderMapping = {
            y:     (dateTime: SBDateTime) => dateTime.year.toString(),
            yy:    (dateTime: SBDateTime) => dateTime.year.toString().padStart(2, '0'),
            yyy:   (dateTime: SBDateTime) => dateTime.year.toString().padStart(3, '0'),
            yyyy:  (dateTime: SBDateTime) => dateTime.year.toString().padStart(4, '0'),
            yyyyy: (dateTime: SBDateTime) => dateTime.year.toString().padStart(5, '0'),
            M:     (dateTime: SBDateTime) => dateTime.month.toString(),
            MM:    (dateTime: SBDateTime) => dateTime.month.toString().padStart(2, '0'),
            MMM:   (dateTime: SBDateTime) => localizationInfo.AbbreviatedMonthGenitiveNames[dateTime.month - 1],
            MMMM:  (dateTime: SBDateTime) => localizationInfo.MonthGenitiveNames[dateTime.month - 1],
            d:     (dateTime: SBDateTime) => dateTime.dayOfMonth.toString(),
            dd:    (dateTime: SBDateTime) => dateTime.dayOfMonth.toString().padStart(2, '0'),
            ddd:   (dateTime: SBDateTime) => localizationInfo.AbbreviatedDayNames[dateTime.dayOfWeek],
            dddd:  (dateTime: SBDateTime) => localizationInfo.DayNames[dateTime.dayOfWeek]
        }
    }

    public static Now(): SBDateTime {
        return new SBDateTime(new Date());
    }

    public static FromISO(dateString: string): SBDateTime {
        return new SBDateTime(SBDateTime.ISOtoJSDate(dateString));
    }

    public static FromJSDate(jsDate: Date): SBDateTime {
        const date = new Date(jsDate);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid JS date');
        }

        return new SBDateTime(date);
    }

    public static FromMilliseconds(milliseconds: number): SBDateTime {
        const date = new Date(milliseconds);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid date milliseconds');
        }
        
        return new SBDateTime(date);
    }

    public static FromSBDateAndSBTime(date: SBDateTime, time: SBDateTime) {
        return new SBDateTime(new Date(date.year, date.month-1, date.dayOfMonth, time.hour, time.minute, time.second));
    }

    protected static ISOtoJSDate(dateString: string) {
        let matches: RegExpMatchArray | null;
        try {
            matches = dateString.match(SBDateTime._isoRegex);
            if (!matches) {
                throw new Error('Invalid ISO date');
            }
        } catch {
            throw new Error('Invalid ISO date');
        }
        
        let date: Date;
        const year = Number(matches[1]);
        const month = Number(matches[2]) - 1;
        const dayOfMonth = Number(matches[3]);
        if (matches[4] == undefined) {
            date = new Date(year, month, dayOfMonth);

            if (date.getFullYear() != year || date.getMonth() != month) {
                throw new Error('Invalid ISO date');
            }
        } else {
            const hour = Number(matches[4]);
            const minute = Number(matches[5]);
            const second = Number(matches[6]);
            date = new Date(year, month, dayOfMonth, hour, minute, second);

            if (date.getFullYear() != year || date.getMonth() != month || hour > 23 || minute > 59 || second > 59) {
                throw new Error('Invalid ISO date');
            }
        }

        return date;
    }


    // Instance

    protected _date!: Date;

    public constructor(date: Date) {
        this._date = date;
    }

    protected get dateWithoutTimezone(): Date {
        return new Date(this._date.getTime() - this._date.getTimezoneOffset() * 60000);
    }

    public ToISO(): string {
        return this.dateWithoutTimezone.toISOString().slice(0,-5);
    }

    public ToJSDate(): Date {
        return new Date(this._date);
    }

    public Clone(): SBDateTime {
        return new SBDateTime(new Date(this._date));
    }

    public set year(value: number) {
        if (value < 1900 || value > 2100) {
            throw new Error('Setting invalid year value');
        }
        this._date.setFullYear(value);
    }
    public get year(): number {
        return this._date.getFullYear();
    }

    public set month(value: number) {
        if (value < 1 || value > 12) {
            throw new Error('Setting invalid month value');
        }
        this._date.setMonth(value - 1);
    }
    public get month(): number {
        return this._date.getMonth() + 1;
    }

    public set dayOfMonth(value: number) {
        let testDate = new Date(this._date.getFullYear(), this._date.getMonth(), value);
        if (testDate.getDate() != value) {
            throw new Error('Setting invalid day-of-month value');
        }
        this._date.setDate(value);
    }
    public get dayOfMonth(): number {
        return this._date.getDate();
    }

    public get dayOfWeek(): number {
        return this._date.getDay();
    }

    public set hour(value: number) {
        if (value < 0 || value >= 24) {
            throw new Error('Setting invalid hour value');
        }
        this._date.setHours(value);
    }
    public get hour(): number {
        return this._date.getHours();
    }

    public set minute(value: number) {
        if (value < 0 || value >= 60) {
            throw new Error('Setting invalid minute value');
        }
        this._date.setMinutes(value);
    }
    public get minute(): number {
        return this._date.getMinutes();
    }

    public set second(value: number) {
        if (value < 0 || value >= 60) {
            throw new Error('Setting invalid second value');
        }
        this._date.setSeconds(value);
    }
    public get second(): number {
        return this._date.getSeconds();
    }

    public get numberOfDaysInMonth(): number {
        return new Date(this._date.getFullYear(), this._date.getMonth()+1, 0).getDate();
    }

    public get totalMilliseconds(): number {
        return this._date.getTime();
    }

    // These should be static getters, but for some reason the compiler throws up an error if they are
    public get hourPlacement(): number {
        return SBDateTime._hourIndex;
    }

    public get minutePlacement(): number {
        return SBDateTime._minuteIndex;
    }

    public get meridianPlacement(): number {
        return SBDateTime._meridianIndex;
    }

    public AddMinutes(minutes: number): this {
        this._date.setMinutes(this._date.getMinutes() + minutes);
        return this;
    }

    public AddHours(hours: number): this {
        this._date.setHours(this._date.getHours() + hours);
        return this;
    }

    public AddDays(days: number): this {
        this._date.setDate(this._date.getDate() + days);
        return this;
    }
    public AddMonths(months: number): this {
        this._date.setMonth(this._date.getMonth() + months);
        return this;
    }

    public AddYears(years: number): this {
        this._date.setFullYear(this._date.getFullYear() + years);
        return this;
    }
}
