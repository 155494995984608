import { RegistrationPaymentType, StudentStatus, StudentTypes, WebsiteBlockContactFormType, WebsiteBlockEmbedContentType, WebsiteBlockEmbedType, WebsiteBlockSignupFormType, WebsiteBlockTextAndMediaType, WebsiteBlockType } from "../api/Enums";
import { WebsiteBlockDisplayItem } from "../api/WebsiteBlockDisplayItem";
import { WebsiteBlockSignupFormDisplayItem } from "../api/WebsiteBlockSignupFormDisplayItem";
import { WebsiteCustomFieldSignupFormDisplayItem } from "../api/WebsiteCustomFieldSignupFormDisplayItem";
import { WebsiteRenderContextDisplayItem } from "../api/WebsiteRenderContextDisplayItem";
import { WebsiteBlockTextAndMediaDisplayItem } from "../api/WebsiteBlockTextAndMediaDisplayItem";
import { WebsiteBlockContactFormDisplayItem } from "../api/WebsiteBlockContactFormDisplayItem";
import { WebsiteBlockEmbedDisplayItem, WebsiteBlockLoginFormDisplayItem, WebsiteBlockTextAndMediaImageDisplayItem } from "../public-api";


declare module '../api/WebsiteBlockDisplayItem' {
    namespace WebsiteBlockDisplayItem {
        let IsSignupForm: typeof isSignupForm;
        let AsSignupForm: typeof asSignupForm;
        let IsTextAndMedia: typeof isTextAndMedia;
        let AsTextAndMedia: typeof asTextAndMedia;
        let IsContactForm: typeof isContactForm;
        let AsContactForm: typeof asContactForm;
        let IsLoginForm: typeof isLoginForm;
        let AsLoginForm: typeof asLoginForm;
        let IsEmbed: typeof isEmbed; 
        let AsEmbed: typeof asEmbed; 
    }

    export interface WebsiteBlockDisplayItem {
        GUID?: string
    }
}


WebsiteBlockDisplayItem.IsSignupForm = isSignupForm;
WebsiteBlockDisplayItem.AsSignupForm = asSignupForm;
WebsiteBlockDisplayItem.IsTextAndMedia = isTextAndMedia;
WebsiteBlockDisplayItem.AsTextAndMedia = asTextAndMedia;
WebsiteBlockDisplayItem.IsContactForm = isContactForm;
WebsiteBlockDisplayItem.AsContactForm = asContactForm;
WebsiteBlockDisplayItem.IsLoginForm = isLoginForm;
WebsiteBlockDisplayItem.AsLoginForm = asLoginForm;
WebsiteBlockDisplayItem.IsEmbed = isEmbed; 
WebsiteBlockDisplayItem.AsEmbed = asEmbed; 

function isSignupForm(item: WebsiteBlockDisplayItem): item is WebsiteBlockSignupFormDisplayItem {
    return item.WebsiteBlockType === WebsiteBlockType.SignupForm;
}

function asSignupForm(item: WebsiteBlockDisplayItem): WebsiteBlockSignupFormDisplayItem {
    if (isSignupForm(item)) { return item; }
    throw Error(`${WebsiteBlockDisplayItem.ClassName} is not a ${WebsiteBlockSignupFormDisplayItem.ClassName}.`);
}

function isTextAndMedia(item: WebsiteBlockDisplayItem): item is WebsiteBlockTextAndMediaDisplayItem {
    return item.WebsiteBlockType === WebsiteBlockType.TextAndMedia;
}

function asTextAndMedia(item: WebsiteBlockDisplayItem): WebsiteBlockTextAndMediaDisplayItem {
    if (isTextAndMedia(item)) { return item; }
    throw Error(`${WebsiteBlockDisplayItem.ClassName} is not a ${WebsiteBlockTextAndMediaDisplayItem.ClassName}.`);
}

function isContactForm(item: WebsiteBlockDisplayItem): item is WebsiteBlockContactFormDisplayItem {
    return item.WebsiteBlockType === WebsiteBlockType.ContactForm;
}

function asContactForm(item: WebsiteBlockDisplayItem): WebsiteBlockContactFormDisplayItem {
    if (isContactForm(item)) { return item; }
    throw Error(`${WebsiteBlockDisplayItem.ClassName} is not a ${WebsiteBlockContactFormDisplayItem.ClassName}.`);
}

function isLoginForm(item: WebsiteBlockDisplayItem): item is WebsiteBlockLoginFormDisplayItem {
    return item.WebsiteBlockType === WebsiteBlockType.LoginForm;
}

function asLoginForm(item: WebsiteBlockDisplayItem): WebsiteBlockLoginFormDisplayItem {
    if (isLoginForm(item)) { return item; }
    throw Error(`${WebsiteBlockDisplayItem.ClassName} is not a ${WebsiteBlockLoginFormDisplayItem.ClassName}.`);
}

function isEmbed(item: WebsiteBlockDisplayItem): item is WebsiteBlockEmbedDisplayItem {
    return item.WebsiteBlockType === WebsiteBlockType.Embed; 
}

function asEmbed(item: WebsiteBlockDisplayItem): WebsiteBlockEmbedDisplayItem {
    if (isEmbed(item)) {return item; }
    throw Error(`${WebsiteBlockDisplayItem.ClassName} is not a ${WebsiteBlockEmbedDisplayItem.ClassName}.`);
}

// Convenience class to set request fields for WebsiteBlock retrieval
export class WebsiteBlockAnyDisplayItem implements WebsiteBlockDisplayItem, WebsiteBlockSignupFormDisplayItem, WebsiteBlockTextAndMediaDisplayItem, WebsiteBlockContactFormDisplayItem, WebsiteBlockEmbedDisplayItem {
    Source!: string | null;
    IsFullWidth!: boolean;
    ContentType!: WebsiteBlockEmbedContentType;
    EmbedType!: WebsiteBlockEmbedType;
    Location!: string | null;
    GUID?: string | undefined;
    Header!: string | null;
    Body!: string | null;
    Images!: WebsiteBlockTextAndMediaImageDisplayItem[];
    Image!: WebsiteBlockTextAndMediaImageDisplayItem;
    ContactFormType!: WebsiteBlockContactFormType;
    SignupFormType!: WebsiteBlockSignupFormType;
    TextAndMediaType!: WebsiteBlockTextAndMediaType;
    CollectAddress!: boolean;
    CollectBirthday!: boolean;
    CollectCreditCard!: boolean;
    CollectFaceTimeID!: boolean;
    CollectGender!: boolean;
    CollectLocalSchool!: boolean;
    CollectPreferredTeacher!: boolean;
    CollectReferrer!: boolean;
    CollectSkillLevel!: boolean;
    CollectSkypeUsername!: boolean;
    CollectSubjectType!: boolean;
    DefaultStudentStatus!: StudentStatus;
    AllowedStudentTypes!: StudentTypes;
    SendConfirmationEmail!: boolean;
    SendLoginInformation!: boolean;
    RegistrationFee!: number | null;
    RegistrationFeeType!: RegistrationPaymentType;
    RegistrationFeeDescription!: string | null;
    ThankYouMessage!: string | null;
    CustomFields!: WebsiteCustomFieldSignupFormDisplayItem[];
    ID!: string | null;
    WebsitePageID!: string | null;
    WebsiteID!: string | null;
    OrderIndex!: number;
    RenderContext!: WebsiteRenderContextDisplayItem | null;
    ScriptTag!: string | null;
    Url!: string | null;
    WebsiteBlockType!: WebsiteBlockType;
    SchoolID!: string | null;
    StudentGroupIDs!: string[];
}
