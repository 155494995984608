<lib-dialog-layout>
    <ng-container title>
        {{ data.Title }}
    </ng-container>
    <ng-container content>
        <p>{{ data.Text }}</p>
    </ng-container>
    <ng-container actions>

            <lib-button
                id="LibraryDialogSecondaryButton"
                [kind]="ButtonKind.Secondary"
                [size]="ButtonSize.Large"
                [disabled]="primaryActionClicked"
                (clicked)="DismissAction()">
                <ng-container label>{{ data.SecondaryButtonText }}</ng-container>
            </lib-button>
            <lib-button
                id="LibraryDialogPrimaryButton"
                [kind]="ButtonKind.Primary"
                [size]="ButtonSize.Large"
                [loading]="primaryActionClicked"
                (clicked)="PrimaryAction()">
                <ng-container label>{{ data.PrimaryButtonText }}</ng-container>
            </lib-button>

    </ng-container>
</lib-dialog-layout>
