import { Pipe, PipeTransform } from '@angular/core';
import { SBDate } from '../date-time/sb-date';
import { DateFormat } from '../date-time/sb-date-time';

@Pipe({
    name: 'formatDate'
})
export class LocalizationDatePipe implements PipeTransform {
    
    transform(value: string, pattern: DateFormat) : string {
        try {
            return SBDate.FromISO(value).Render(pattern);
        } catch {
            return '';
        }
    }
}
