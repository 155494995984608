<lib-card class="scss-signup-form-card">
    <p class="typography-title-small pb-1" i18n="@@SUFStep2FamilyContactHeader">#Family# Contact</p>
    <p *ngIf="adultStudents.length === 0" class="typography-body-small" i18n="@@SUFStep2ChildFamilyContactHelpMessage">
        To complete the registration for a #child#, please provide the contact information for an adult #family# member.
    </p>
    <p *ngIf="adultStudents.length > 0" class="typography-body-small" i18n="@@SUFStep2AdultFamilyContactHelpMessage">
        Select the #family# contact or add an additional contact.
    </p>
    <form [formGroup]="addContactForm" class="pt-3">
        <div class="single-column-row-website-form" *ngIf="adultStudents.length > 0">
            <lib-radio-group [formControl]="addContactForm.controls.PrimaryContact"
                [orientation]="Orientation.Vertical" (change)="PrimaryContactChanged($event)">
                <lib-radio-group-item *ngFor="let student of adultStudents"
                [value]="student.ID">
                    {{student.FirstName}} {{student.LastName}}
                </lib-radio-group-item>
                <lib-radio-group-item
                    [value]="ContactType.NewContact">
                        <ng-container i18n="@@SUFManuallyInputNewContact">New contact</ng-container>
                </lib-radio-group-item>
            </lib-radio-group>
        </div>

        <!-- DEFAULT -->
        <div class="two-column-row-website-form">
            <lib-input-text
                [formControl]="addContactForm.controls.FirstName"
                [maxCharacterLength]="60"
                [errorMessages]="firstNameError">
                <ng-container label i18n="@@SUFDesignBlockFirstName">
                    First Name
                </ng-container>
            </lib-input-text>

            <lib-input-text
                [formControl]="addContactForm.controls.LastName"
                [maxCharacterLength]="60"
                [errorMessages]="lastNameError">
                <ng-container label i18n="@@SUFDesignBlockLastName">
                    Last Name
                </ng-container>
            </lib-input-text>
        </div>

        <div class="single-column-row-website-form">
            <lib-input-text [formControl]="addContactForm.controls.Email"
                [maxCharacterLength]="190"
                [errorMessages]="emailError">
                <ng-container label i18n="@@SUFDesignBlockEmail">
                    Email
                </ng-container>
            </lib-input-text>
        </div>

        <!-- ADDITIONAL FIELDS -->
        <div class="two-column-row-website-form">
            <lib-input-text
                [formControl]="addContactForm.controls.HomePhone"
                [errorMessages]="phoneError"
                inputFilter="[0-9-\(\)\+]"
                (change)="HomeChanged($event)">
                <ng-container label i18n="@@SUFDesignBlockHomePhone">
                    Home Phone
                </ng-container>
            </lib-input-text>

            <lib-input-text
                [formControl]="addContactForm.controls.MobilePhone"
                [errorMessages]="phoneError"
                inputFilter="[0-9-\(\)\+]"
                (change)="MobileChanged($event)">
                <ng-container label i18n="@@SUFDesignBlockMobilePhone">
                    Mobile Phone
                </ng-container>
            </lib-input-text>
        </div>

        <div class="single-column-row-website-form" *ngIf="data.CollectAddress">
            <lib-input-text-area
                [formControl]="addContactForm.controls.Address"
                [errorMessages]="addressError">
                <ng-container label i18n="@@SUFDesignBlockAddress">
                    Address
                </ng-container>
            </lib-input-text-area>
        </div>

        <div class="single-column-row-website-form" *ngIf="data.CollectReferrer">
            <lib-input-text
                [formControl]="addContactForm.controls.Referrer">
                <ng-container label i18n="@@SUFDesignBlockRefferer">
                    How did you hear about us?
                </ng-container>
            </lib-input-text>
        </div>

        <lib-signup-form-custom-fields
            [kind]="FormAffinity.Form"
            [fields]="data.CustomFields"
            [form]="addContactForm">
        </lib-signup-form-custom-fields>

    </form>
</lib-card>

<ng-content></ng-content>

<div class="pt-2" [ngClass]="{
    'd-flex flex-row w-100 justify-content-end gap-2': !isMobile
    }">

    <div [ngClass]="{
        'mb-2': isMobile
        }">
        <lib-button
            class="scss-signupform-outline-secondary-btn-accent-color"
            [kind]="ButtonKind.Secondary"
            [size]="isMobile ? ButtonSize.FullWidth : ButtonSize.Medium"
            [disabled]="disableSave"
            (clicked)="BackClicked()">
            <ng-container label i18n="@@CommonBackButton">Back</ng-container>
        </lib-button>
    </div>

    <div>
        <lib-button
            class="scss-widget-primary-btn-accent-color"
            [kind]="ButtonKind.Primary"
            [size]="isMobile ? ButtonSize.FullWidth : ButtonSize.Medium"
            (clicked)="NextClicked()"
            [disabled]="disableSave">
            <ng-container label>
                <ng-container *ngIf="data.CollectCreditCard && supportsStoredPaymentMethods" i18n="@@CommonNextButton">Next</ng-container>
                <ng-container *ngIf="!(data.CollectCreditCard && supportsStoredPaymentMethods)" i18n="@@CommonSubmitButton">Submit</ng-container>
            </ng-container>
        </lib-button>
    </div>
</div>
