<div class="v3">
    <span mat-dialog-title *ngIf="showTitle">
        <h3>
            <ng-content select="[title]"></ng-content>
        </h3>
    </span>

    <mat-dialog-content>
        <div class="scroll-shadow-cover">
            <ng-content select="[content]"></ng-content>
        </div>
    </mat-dialog-content>

    <ng-container *ngIf="showActions">

        <mat-divider class="pb-3 dialog-divider"></mat-divider>

        <mat-dialog-actions>
            <lib-layout-row [orientation] = "orientation">
                <ng-content rowRight select="[actions]"></ng-content>
                <ng-content rowLeft select="[actionsSecondary]"></ng-content>
            </lib-layout-row>
        </mat-dialog-actions>

    </ng-container>
</div>
