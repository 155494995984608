import { WebsiteBlockContactFormDisplayItem, WebsiteBlockEmbedContentType, WebsiteBlockEmbedDisplayItem, WebsiteBlockEmbedType, WebsiteBlockLoginFormDisplayItem, WebsiteBlockTextAndMediaDisplayItem } from "@library/data-models";
import { WebsiteBlockDisplayItem } from "@library/data-models";
import { WebsiteBlockContactFormType, WebsiteBlockLoginFormType, WebsiteBlockSignupFormType, WebsiteBlockTextAndMediaType, WebsiteBlockType } from "@library/data-models"

export const photoGalleryCarouselMaxItems: number = 10;

export const ContactWidgetBlockPlaceholderText: Record<WebsiteBlockContactFormType, {header: string, body: string}> = {
    [WebsiteBlockContactFormType.Default]: { header: $localize `:@@TPWebsiteContactWidgetBlock:Contact Us`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockContactFormType.AccentBackgroundDefault]: { header: $localize `:@@TPWebsiteContactWidgetBlock:Contact Us`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockContactFormType.MediaLeft]: { header: $localize `:@@TPWebsiteContactWidgetBlock:Contact Us`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockContactFormType.MediaRight]: { header: $localize `:@@TPWebsiteContactWidgetBlock:Contact Us`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
}

export enum LoginFormMode {
    Normal = "Normal",
    ForgotPassword = "ForgotPassword",
    AlreadyLoggedIn = "AlreadyLoggedIn",
    MultipleJWTs = "MultipleJWTs",
    ResetPasswordThankYou = "ResetPasswordThankYou",
    ManualRedirect = "ManualRedirect",
    MfaRequired = 'MfaRequired'
}

export const LoginWidgetBlockPlaceholderText: Record<WebsiteBlockLoginFormType, {header: string, body: string}> = {
    [WebsiteBlockLoginFormType.Default]: { header: $localize `:@@TPWebsitLoginWidgetBlock:Login`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockLoginFormType.AccentBackgroundDefault]: { header: $localize `:@@TPWebsiteLoginWidgetBlock:Login`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockLoginFormType.MediaLeft]: { header: $localize `:@@TPWebsiteLoginWidgetBlock:Login`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockLoginFormType.MediaRight]: { header: $localize `:@@TPWebsiteLoginWidgetBlock:Login`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
}

export const SignupWidgetBlockPlaceholderText: Record<WebsiteBlockSignupFormType, {header: string, body: string}> = {
    [WebsiteBlockSignupFormType.Default]: { header: $localize `:@@TPWebsitSignupWidgetBlock:Sign-Up`, body:''},
    [WebsiteBlockSignupFormType.AccentBackgroundDefault]: { header: $localize `:@@TPWebsitSignupWidgetBlock:Sign-Up`, body:''},
    [WebsiteBlockSignupFormType.MediaLeft]: { header: $localize `:@@TPWebsitSignupWidgetBlock:Sign-Up`, body:''},
    [WebsiteBlockSignupFormType.MediaRight]: { header: $localize `:@@TPWebsitSignupWidgetBlock:Sign-Up`, body:''},
}

export class WebsiteBuildingBlock {
    kind!: WebsiteBuildingBlockKind;
    type!: WebsiteBlockTextAndMediaType | WebsiteBlockWidgetType | WebsiteBlockEmbedContentType;
}

export class WebsiteBuildingBlockTextAndMedia extends WebsiteBuildingBlock {

    hasHeader!: boolean;
    hasBody: boolean = true;
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.TextAndMedia;

    constructor(initializer?: Partial<WebsiteBuildingBlockTextAndMedia>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockHTML extends WebsiteBuildingBlock {
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.HTML;
    constructor(initializer?: Partial<WebsiteBuildingBlockHTML>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}
export class WebsiteBuildingBlockBlog extends WebsiteBuildingBlock {
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.Blog;
    constructor(initializer?: Partial<WebsiteBuildingBlockBlog>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockWidget extends WebsiteBuildingBlock {
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.Widget;

    constructor(initializer?: Partial<WebsiteBuildingBlockWidget>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockContactWidget extends WebsiteBuildingBlockWidget {
    override type: WebsiteBlockWidgetType = WebsiteBlockWidgetType.Contact;
    contactType: WebsiteBlockContactFormType = WebsiteBlockContactFormType.Default;

    hasHeader!: boolean;
    hasBody!: boolean;

    constructor(initializer?: Partial<WebsiteBuildingBlockContactWidget>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockLoginWidget extends WebsiteBuildingBlockWidget {
    override type: WebsiteBlockWidgetType = WebsiteBlockWidgetType.Login;
    loginType: WebsiteBlockLoginFormType = WebsiteBlockLoginFormType.Default;

    hasHeader!: boolean;
    hasBody!: boolean;

    constructor(initializer?: Partial<WebsiteBuildingBlockLoginWidget>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockSignupWidget extends WebsiteBuildingBlockWidget {
    override type: WebsiteBlockWidgetType = WebsiteBlockWidgetType.Signup;
    signupType: WebsiteBlockSignupFormType = WebsiteBlockSignupFormType.Default;

    constructor(initializer?: Partial<WebsiteBuildingBlockSignupWidget>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}


export class WebsiteBuildingBlockEmbed extends WebsiteBuildingBlock {
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.Embed;
    embedType : WebsiteBlockEmbedType = WebsiteBlockEmbedType.Default; 
    source: string | null = null;
    isFullWidth : boolean = false; 
    constructor(initializer?: Partial<WebsiteBuildingBlockEmbed>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockMap extends WebsiteBuildingBlockEmbed {
    override type : WebsiteBlockEmbedContentType = WebsiteBlockEmbedContentType.Map; 
    constructor(initializer?: Partial<WebsiteBuildingBlockEmbed>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}
export enum WebsiteBuildingBlockKind {
    TextAndMedia,
    HTML,
    Hero,
    Gallery,
    Testimonial,
    Widget,
    Blog, 
    Embed
}

export enum WebsiteBlockWidgetType {
    Signup = "Signup",
    Contact = "Contact",
    Login = "Login"
}

export enum WebsiteGalleryItemAction {
    LeftSwap = "LeftSwap",
    RightSwap = "RightSwap",
    Delete = "Delete",
    MediaGallery = "MediaGallery",
    MediaDetails = "MediaDetails"
}


export const TextMediaBlockPlaceholderText: Record<WebsiteBlockTextAndMediaType, {header: string, body: string}> = {
    [WebsiteBlockTextAndMediaType.Default]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockTextAndMediaType.TwoColumn]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet' },
    [WebsiteBlockTextAndMediaType.AccentBackgroundDefault]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet' },
    [WebsiteBlockTextAndMediaType.MediaLeft]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockTextAndMediaType.MediaRight]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockTextAndMediaType.MediaLeftClipped]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, labore! Ex quo quidem tempora nulla, quod ut distinctio adipisci odit minus corrupti cupiditate voluptatum aspernatur itaque esse atque molestias maiores enim tempore odio ipsa magnam error eligendi. Minus dolore totam praesentium!' },
    [WebsiteBlockTextAndMediaType.MediaRightClipped]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, labore! Ex quo quidem tempora nulla, quod ut distinctio adipisci odit minus corrupti cupiditate voluptatum aspernatur itaque esse atque molestias maiores enim tempore odio ipsa magnam error eligendi. Minus dolore totam praesentium!' },
    [WebsiteBlockTextAndMediaType.MediaLeftTwoColumn]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockTextAndMediaType.MediaRightTwoColumn]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae. Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.Lorem ipsum dolor sit amet consectetur. Morbi auctor at ornare tristique faucibus fames vitae.' },
    [WebsiteBlockTextAndMediaType.Testimonial]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: '<div class="typography-testimonial-quote-caption-large">"Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean."</div><br><div class="typography-testimonial-person-name-and-title"><div class="typography-testimonial-person-name">Person Name</div><div class="typography-testimonial-person-title">Co-founder at Company</div></div>' },
    [WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: '<div class="typography-testimonial-quote-caption-large">"Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar."</div><div class="typography-testimonial-person-name-and-title"><div class="typography-testimonial-person-name">Person Name</div><div class="typography-testimonial-person-title">Co-founder at Company</div></div>' },
    [WebsiteBlockTextAndMediaType.MediaRightTestimonial]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: '<div class="typography-testimonial-quote-caption-large">"Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean."</div><br><div class="typography-testimonial-person-name-and-title"><div class="typography-testimonial-person-name">Person Name</div><div class="typography-testimonial-person-title">Co-founder at Company</div></div>' },
    [WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: '<div class="typography-testimonial-quote-caption-large">"Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar."</div><div class="typography-testimonial-person-name-and-title"><div class="typography-testimonial-person-name">Person Name</div><div class="typography-testimonial-person-title">Co-founder at Company</div></div>' },
    [WebsiteBlockTextAndMediaType.MediaLeftShadow]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, labore! Ex quo quidem tempora nulla, quod ut distinctio adipisci odit minus corrupti cupiditate voluptatum aspernatur itaque esse atque molestias maiores enim tempore odio ipsa magnam error eligendi. Minus dolore totam praesentium!' },
    [WebsiteBlockTextAndMediaType.MediaRightShadow]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, labore! Ex quo quidem tempora nulla, quod ut distinctio adipisci odit minus corrupti cupiditate voluptatum aspernatur itaque esse atque molestias maiores enim tempore odio ipsa magnam error eligendi. Minus dolore totam praesentium!' },
    [WebsiteBlockTextAndMediaType.HTML]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: '<p>This is an HTML code block. To edit this section, use the edit button.</p>' },
    [WebsiteBlockTextAndMediaType.HeroNoImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur.' },
    [WebsiteBlockTextAndMediaType.HeroBackgroundImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur.' },
    [WebsiteBlockTextAndMediaType.HeroClippedSideImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur.' },
    [WebsiteBlockTextAndMediaType.HeroSquareSideImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur.' },
    [WebsiteBlockTextAndMediaType.MediaLeftHeroSquareImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur.' },
    [WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur.' },
    [WebsiteBlockTextAndMediaType.PhotoGalleryGrid]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur.' },
    [WebsiteBlockTextAndMediaType.PhotoGalleryCarousel]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur.' },
    [WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: 'Lorem ipsum dolor sit amet consectetur.' },
    [WebsiteBlockTextAndMediaType.GallerySquareColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: 'Lorem ipsum dolor sit amet consectetur.'
    },
    [WebsiteBlockTextAndMediaType.GalleryCircleColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: 'Lorem ipsum dolor sit amet consectetur.'
    },
    [WebsiteBlockTextAndMediaType.GallerySquareAccentColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: 'Lorem ipsum dolor sit amet consectetur.'
    },
    [WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: 'Lorem ipsum dolor sit amet consectetur.'
    },
    [WebsiteBlockTextAndMediaType.GallerySquareCardColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: 'Lorem ipsum dolor sit amet consectetur.'
    },
    [WebsiteBlockTextAndMediaType.GalleryCircleCardColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: 'Lorem ipsum dolor sit amet consectetur.'
    }
}

export const TextAndMediaImagePlaceholderText = {
    header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
    body: 'Lorem ipsum dolor sit amet consectetur.'
};

export const defaultContactFormThankYouMessage = $localize`:@@TPContactWidgetSettingsThankYouDefault:<div>
    <h2 style="text-align: center; padding-bottom: .75rem";>Thank you for reaching out!</h2>
    <p style="text-align: center;">Your message has been successfully submitted. We’ll get back to you as soon as possible.</p>
</div>`;

export const defaultSignupFormThankYouMessage = $localize`:@@TPSignupWidgetSettingsThankYouDefault:<div>
    <h2 style="text-align: center; padding-bottom: .75rem";>Thank you for signing up!</h2>
    <p style="text-align: center;">We will contact you as soon as possible.</p>
</div>`;

export const WEBSITE_PREFIX_ID = 'new_';

export interface WebsiteBlockTypeData {
    BlockType: WebsiteBlockType;
    BlockSubtype: WebsiteBlockContactFormType | WebsiteBlockLoginFormType | WebsiteBlockTextAndMediaType;
}

export interface WebsiteBlockImageIndexItem {
    ImageIndex: number;
    Block: WebsiteBlockTextAndMediaDisplayItem | WebsiteBlockContactFormDisplayItem | WebsiteBlockLoginFormDisplayItem;
}

