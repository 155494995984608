import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BannerModule } from '@library/banner';
import { BaseModule } from '@library/base';
import { ButtonModule } from '@library/button';
import { DialogModule } from '@library/dialog';
import { DragAndDropModule } from '@library/drag-and-drop';
import { EmptyStateModule } from '@library/empty-state';
import { FormFieldModule } from '@library/form-field';
import { IconModule } from '@library/icon';
import { LayoutModule } from '@library/layout';
import { LocalizationModule } from '@library/localization';
import { StatusLabelModule } from '@library/status-label';
import { AddPaymentSourceComponent } from './add/add-payment-source.component';
import { PaymentSourcesComponent } from './payment-sources.component';
import { PaymentSourceIconPipe } from './pipes/payment-source-icon.pipe';
import { PaymentSourcesAddPaypalProComponent } from './add/paypal-pro/payment-sources-add-paypal-pro.component';
import { PaymentSourcesAddStripeComponent } from './add/stripe/payment-sources-add-stripe.component';
import { PaymentSourcesAddPayPalCommercePlatformComponent } from './add/paypal-commerce-platform/payment-sources-add-paypal-commerce-platform.component';


@NgModule({
    declarations: [
        PaymentSourcesComponent,
        PaymentSourceIconPipe,
        AddPaymentSourceComponent,
        PaymentSourcesAddPaypalProComponent,
        PaymentSourcesAddStripeComponent,
        PaymentSourcesAddPayPalCommercePlatformComponent
    ],
    imports: [
        CommonModule,
        DragAndDropModule,
        StatusLabelModule,
        ButtonModule,
        IconModule,
        EmptyStateModule,
        DialogModule,
        ReactiveFormsModule,
        FormFieldModule,
        MatTooltipModule,
        BannerModule,
        LocalizationModule,
        BaseModule,
        LayoutModule
    ],
    exports: [
        PaymentSourcesComponent,
        AddPaymentSourceComponent
    ]
})
export class PaymentSourcesModule { }
