import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'lib-drag-and-drop-item',
    templateUrl: './drag-and-drop-item.component.html',
})
export class DragAndDropItemComponent<T> {
    @Input() value!: T;
    @Input() id?: string;
    @ViewChild('content', {static: true, read: TemplateRef}) content!: TemplateRef<any>;
    @ViewChild('menu', {static: true, read: TemplateRef}) menu!: TemplateRef<any>;
}