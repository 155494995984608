
<div class="boundary">
    <div cdkDropList
        class="payment-option-list data-hj-suppress"
        (cdkDropListDropped)="Dropped($event)">
        <div *ngFor="let item of sortedItemList"
            class="payment-option-box" cdkDragLockAxis="y"
            cdkDragBoundary=".boundary"
            cdkDrag [cdkDragDisabled]="disableDrag"
            [id]="item.id">

            <div *cdkDragPlaceholder class="payment-option-custom-placeholder"></div>
            <div cdkDragHandle class="payment-option-handle">
                <lib-icon [ngStyle]="{'visibility': disableDrag ? 'hidden' : 'inherit'}" class="neutral-500" [icon]="Icon.Common.DragIndicator"
                    [size]="IconSize.Medium"></lib-icon>
            </div>
            <div class="card data-hj-suppress">
                <ng-container *ngTemplateOutlet="item.content"></ng-container>
            </div>

            <ng-container *ngTemplateOutlet="item.menu"></ng-container>
        </div>
    </div>
</div>
