import { SBDateTime, TimeFormat } from './sb-date-time';

export class SBTime extends SBDateTime {

    // Used to initialize year/month/day component of Date object
    private static _today = new Date();
    private static _thisYear = SBTime._today.getFullYear();
    private static _thisMonth = SBTime._today.getMonth();
    private static _thisDay = SBTime._today.getDate();
    private static _isoPrefix = SBTime._today.toISOString().slice(0, 11);

    public static override Now(): SBTime {
        return new SBTime(new Date());
    }

    public override Clone(): SBTime {
        return new SBTime(new Date(this._date));
    }

    public static override FromISO(dateString: string): SBTime {
        if (dateString.length <= 12) {
            dateString = SBTime._isoPrefix + dateString;
        }

        const date = SBDateTime.ISOtoJSDate(dateString);

        date.setFullYear(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay);
        return new SBTime(date);
    }

    public static override FromJSDate(jsDate: Date): SBTime {
        const date = new Date(jsDate);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid JS date');
        }

        date.setFullYear(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay);
        return new SBTime(date);
    }

    public static FromMinutes(minutes: number): SBTime {
        if (minutes < 0) {
            throw new Error('Invalid minute offset');
        }

        if (minutes == 1440) { //temporary investigation
            // const date = new Date(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay, 0, 0);
            // return new SBTime(date).AddDays(1);

            return this.FromMinutes(1439);
        }

        const hour = Math.floor(minutes / 60);
        const minute = minutes % 60;
        const date = new Date(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay, hour, minute);

        return new SBTime(date);
    }

    public static FromComponents(hour: number, minute: number, second: number = 0): SBTime {
        const date = new Date(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay, hour, minute, second);
        if (!date || date.getHours() != hour || date.getMinutes() != minute) {
            throw new Error('Invalid time');
        }
        
        return new SBTime(date);
    }

    // Parsing

    public static Parse(timeString: string, format: TimeFormat = 'short'): SBTime {
        switch (format) {
            case 'short': return SBTime.ParseShort(timeString);
            case 'iso': return SBTime.FromISO(timeString);
            default: throw new Error(`Parsing of format '${format}' is unimplemented`);
        }
    }

    private static ParseShort(timeString: string): SBTime {
        const matches = timeString.trim().match(SBDateTime._timeRegExp);
        if (!matches || matches.length < 3) {
            throw new Error(`Could not parse time string ${timeString}`);
        }

        const parsed = matches.slice(1);

        let parsedHour = parsed[SBTime._hourIndex];
        let parsedMinute = parsed[SBTime._minuteIndex];
        if (parsedHour.length > 2) {
            // No separator between hours and minutes, so split them up manually
            if (parsedMinute.length > 0) {
                throw new Error('Invalid time');
            }
            parsedMinute = parsedHour.slice(-2);
            parsedHour = parsedHour.slice(0, -2);
        }

        let hour = Number(parsedHour);
        if (SBTime._is12Hour) {
            if (isNaN(hour) || hour < 1 || hour > 12) {
                throw new Error('Invalid time');
            }
    
            const meridian = parsed[SBTime._meridianIndex];
            const isPM = meridian ? meridian.toLowerCase() == SBTime._pmDesignator.toLowerCase() : hour <= 7;
            
            hour %= 12;
            if (isPM) {
                hour += 12;
            }
        }

        return SBTime.FromComponents(hour, Number(parsedMinute), 0);
    }

    // Rendering

    public Render(format: TimeFormat = 'short'): string {
        switch (format) {
            case 'short': return this.RenderForFormatString(SBTime._shortTimePattern);
            case 'long': return this.RenderForFormatString(SBTime._longTimePattern);
            case 'iso': return this.ToISO();
            default: throw new Error(`Parsing of format ${format} is unimplemented`);
        }
    }

    private RenderForFormatString(formatString: string): string {
        return formatString.replace(SBTime._timeRenderRegExp, match => {
            if (match[0] == "'") {
                return match.slice(1, -1);
            } else {
                return SBTime._timeRenderMapping[match](this);
            }
        });
    }

    public override ToISO(): string {
        return this.dateWithoutTimezone.toISOString().slice(11, -1);
    }
}