import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@library/button';
import { FormFieldModule } from '@library/form-field';
import { LayoutModule } from '@library/layout';
import { UnsavedFormChangesDialogComponent } from './dialogs/unsaved-form-changes-dialog.component';
import { CalendarEventLocationIconPipe } from './pipes/calendar-event-location-icon.pipe';
import { PostIconPipe } from './pipes/post-icon.pipe';
import { DefaultOrderKeyvaluePipe } from './pipes/default-order-key-value.pipe';

@NgModule({
    declarations: [
        PostIconPipe,
        CalendarEventLocationIconPipe,
        UnsavedFormChangesDialogComponent,
        DefaultOrderKeyvaluePipe
    ],
    imports: [
        CommonModule,
        LayoutModule,
        ButtonModule,
        FormFieldModule
    ],
    exports: [
        PostIconPipe,
        CalendarEventLocationIconPipe,
        DefaultOrderKeyvaluePipe
    ]
})
export class ManagersModule { }
