import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiBaseRoutes } from '@library/api';
import { BaseViewComponent, DialogEvents, PaymentContext, PaymentSourceMode, SimpleDialogData } from '@library/base';
import { ErrorCode, PaymentMethod, PaymentSourceDisplayItem, PaymentSourceSearchOptions, PaymentSourceType, PaymentSourceUpdateItem } from '@library/data-models';
import { DialogService } from '@library/dialog';
import { Reordering } from '@library/drag-and-drop';
import { SBDate } from '@library/localization';

@Component({
    selector: 'lib-payment-sources',
    templateUrl: './payment-sources.component.html',
    styleUrls: ['./payment-sources.component.scss']
})
export class PaymentSourcesComponent extends BaseViewComponent implements OnInit {

    private _loading: boolean = true;
    private _isEmptyState: boolean = false;
    private _numPaymentMethods: number = 0;
    private _selectedPaymentSource: PaymentSourceDisplayItem | undefined;

    @Input() forceAutoPay: boolean = false;
    @Input() parentIDs: string| null = null;
    @Input() mode!: PaymentSourceMode;
    @Input() showEmptyState: boolean = true;
    @Input() paymentContext: PaymentContext = PaymentContext.StudentPortal;
    
    private _paymentSources: PaymentSourceDisplayItem[] = [];
    @Input()
    set paymentSources(value: PaymentSourceDisplayItem[]) {
        this._paymentSources = value;
        if(this._paymentSources.length > 0) {
            this.PaymentSourceSelected(this._paymentSources[0]);
        }
    }

    @Output() addItemClicked: EventEmitter<void> = new EventEmitter();
    @Output() paymentSourceSelected: EventEmitter<PaymentSourceDisplayItem> = new EventEmitter();


    private _refetchData: boolean = false;
    @Input()
    set refetchData(value: boolean) {
        this._refetchData = value;
        if (this._refetchData) {
            this.FetchData();
        }
    }

    constructor(private _DialogService: DialogService) {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();
        if(this.mode === PaymentSourceMode.Add || this.mode === PaymentSourceMode.ReadOnly){
            this.FetchData();
        }
    }

    Reordered(event: Reordering<PaymentSourceDisplayItem>) {
        ApiBaseRoutes.PaymentSources.Update.Call({
            Parameter: event.Item.ID!,
            Body: new PaymentSourceUpdateItem({
                AutoPay: event.Item.AutoPay,
                OrderIndex: event.ToOrderIndex
            })
        }).subscribe(_ => {
            this.FetchData();
        });
    }

    MakeDefault(item: PaymentSourceDisplayItem) {
        ApiBaseRoutes.PaymentSources.Update.Call({
            Parameter: item.ID!,
            Body: new PaymentSourceUpdateItem({
                AutoPay: item.AutoPay,
                OrderIndex: 0
            })
        }).subscribe(_ => {
            this.FetchData();
        });

        // Fake the reordering until the API returns
        item.OrderIndex = -1;
        this._paymentSources = JSON.parse(JSON.stringify(this._paymentSources));  // there must be a better way to do this
    }


    AddItem() {
        this.addItemClicked.emit();
    }

    DeleteItem(item: PaymentSourceDisplayItem) {
        const dialogData = new SimpleDialogData({
            Title: $localize`:@@CommonPaymentSourceConfirmRemoveCreditCardTitle:Remove Payment Method`,
            Text: $localize`:@@CommonPaymentSourceConfirmRemovePaymentMethodText:Are you sure you want to remove the selected payment method? This action cannot be undone.`
        });        

        this._DialogService.CreateDeleteDialog(dialogData).events.subscribe(event => {
            switch (event) {
                case DialogEvents.PrimaryAction:
                    ApiBaseRoutes.PaymentSources.Delete.Call({ Parameter: item.ID! }).subscribe({
                        next: _ => {
                            this._DialogService.DismissDialog();
                            this.FetchData();
                        },
                        error: (error: typeof ApiBaseRoutes.PaymentSources.Delete.Error) => {
                            if (error.Response.ErrorCode === ErrorCode.PaymentSourceRequired) {
                                this.ShowPaymentSourceRequiredDialog();
                            } else {
                                throw error;
                            }
                        }
                    });
            }
        });
    }

    ToggleAutoPay(item: PaymentSourceDisplayItem, autoPayEnabled: boolean) {
        ApiBaseRoutes.PaymentSources.Update.Call({
            Parameter: item.ID!,
            Body: new PaymentSourceUpdateItem({
                AutoPay: autoPayEnabled,
                OrderIndex: item.OrderIndex
            })
        }).subscribe(_ => {
            this.FetchData();
        });

        item.AutoPay = autoPayEnabled;
    }

    PaymentSourceSelected(paymentSource: PaymentSourceDisplayItem): void {
        this._selectedPaymentSource = paymentSource;
        this.paymentSourceSelected.emit(paymentSource);
    }

    CreateExtendedItem(paymentSource: PaymentSourceDisplayItem, index: number): PaymentSourceDisplayItem {
        paymentSource.index = index;
        return paymentSource;
    }

    Verify(paymentSource: PaymentSourceDisplayItem): void {
        if (paymentSource.VerificationUrl) {
            window.open(paymentSource.VerificationUrl, '_blank');
            this.PollForVerification(paymentSource);
        }
    }

    private PollForVerification(paymentSource: PaymentSourceDisplayItem) {
        setTimeout(() => {
            ApiBaseRoutes.PaymentSources.Get.Call({
                Parameter: paymentSource.ID!
            }).subscribe({
                next: result => {
                    if (result.IsVerified) {
                        this.FetchData();
                    } else {
                        this.PollForVerification(paymentSource);
                    }
                },
                error: _ => {
                    // In the case of an error (say, if the card has been deleted), just silently stop polling
                }
            })
        }, 2000);
    }

    FormatExpiry(date: string): string {
        const expiry = SBDate.FromISO(date);
        return `${expiry.month.toString().padStart(2, '0')}/${expiry.year.toString().slice(-2)}`;
    }

    FindFirstVerifiedSource(paymentSource: PaymentSourceDisplayItem): boolean {
        const verifiedPaymentSource = this._paymentSources.find(p => p.IsVerified);
        return (verifiedPaymentSource) ? verifiedPaymentSource?.index! === paymentSource.index! : false;
    }

    private FetchData(): void {
        const paymentSourcesSearchOptions = new PaymentSourceSearchOptions();
        paymentSourcesSearchOptions.ParentIDs = this.parentIDs ? [this.parentIDs] : [];
        ApiBaseRoutes.PaymentSources.Search.Call({
            Body: paymentSourcesSearchOptions
        }).subscribe(data => {
            this._loading = false;
            this._paymentSources = data.ItemSubset;
            this._isEmptyState = data.ItemSubset.length === 0 && this.mode !== PaymentSourceMode.ReadOnly;
            this._numPaymentMethods = data.TotalItemCount;
            if(this._paymentSources.length > 0) {
                this.PaymentSourceSelected(this._paymentSources[0]);
            }
        });
    }

    private ShowPaymentSourceRequiredDialog() {
        this._DialogService.CreateInfoDialog(new SimpleDialogData({
            Title: $localize`:@@CommonPaymentSourceAtLeastOnePaymentSourceRequiredErrorTitle:Payment Source Required`,
            Text: $localize`:@@CommonPaymentSourceAtLeastOnePaymentSourceRequiredErrorText:#School# policy requires that all families have at least one verified payment source on file. Please add another credit card (or bank account, if available) before removing this one.`
        })).events.subscribe(event => {
            switch (event) {
                case DialogEvents.PrimaryAction:
                    this._DialogService.DismissDialog();
            }
        });
    }

    get paymentSources(): PaymentSourceDisplayItem[] {
        return this._paymentSources;
    }

    get loading(): boolean {
        return this._loading;
    }

    get PaymentSourceDisplayItem(): typeof PaymentSourceDisplayItem {
        return PaymentSourceDisplayItem;
    }

    get PaymentMethod(): typeof PaymentMethod {
        return PaymentMethod;
    }

    get PaymentSourceType(): typeof PaymentSourceType {
        return PaymentSourceType;
    }

    get isEmptyState(): boolean {
        return this._isEmptyState;
    }

    get hasContent(): boolean {
        return !this._isEmptyState;
    }

    get numPaymentMethods(): number {
        return this._numPaymentMethods;
    }

    get selectedPaymentSource(): PaymentSourceDisplayItem | undefined {
        return this._selectedPaymentSource;
    }

    get PaymentSourceMode(): typeof PaymentSourceMode {
        return PaymentSourceMode;
    }

    get PaymentContext(): typeof PaymentContext {
        return PaymentContext;
    }

}

