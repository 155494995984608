import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarKind } from '@library/base';

@Component({
    selector: 'lib-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

    @Input() kind: ProgressBarKind = ProgressBarKind.Indeterminate;
    @Input() value!: number;

    constructor() { }

    ngOnInit(): void {
    }

}
