import { Component, Input, OnInit } from '@angular/core';
import { DeviceTypeManager, Orientation } from '@library/base';

@Component({
    selector: 'lib-dialog-layout',
    templateUrl: './dialog-layout.component.html',
    styleUrls: ['./dialog-layout.component.scss']
})
export class DialogLayoutComponent implements OnInit {

    @Input() showTitle = true;
    @Input() showActions = true;

    constructor(private _DeviceTypeManager : DeviceTypeManager) { }

    ngOnInit(): void {
    }

    get orientation(): Orientation {
        return this._DeviceTypeManager.isMobile ? Orientation.Vertical : Orientation.Horizontal;
    }

    get isMobile(): boolean {
        return this._DeviceTypeManager.isMobile;
    }

}
