import { Component, Input, OnInit } from '@angular/core';
import { BaseViewComponent, Orientation } from '@library/base';

@Component({
    selector: 'lib-layout-row',
    templateUrl: './layout-row.component.html',
    styleUrls: ['./layout-row.component.scss']
})
export class LayoutRowComponent extends BaseViewComponent implements OnInit {
    @Input() orientation: Orientation = Orientation.Horizontal;
}
