<lib-dialog-layout>
    <ng-container title>
        {{ data.Title }}
    </ng-container>
    <ng-container content>
        <p class="pb-3" *ngIf="data.Text" [innerHtml]="data.Text | trustHtml"></p>
        <form [formGroup]="form">
            <lib-radio-group [useParentFullWidth]="true" [formControl]="form.controls.Option"
                [orientation]="Orientation.Vertical">
                <lib-radio-group-item [value]="RecurringDeleteOptions.PrimaryOption">
                    {{ data.PrimaryOptionText }}
                </lib-radio-group-item>
                <lib-radio-group-item [value]="RecurringDeleteOptions.SecondaryOption">
                    {{ data.SecondaryOptionText }}
                </lib-radio-group-item>
            </lib-radio-group>
        </form>
    </ng-container>
    <ng-container actions>
        <lib-button id="LibraryDialogSecondaryButton" [kind]="ButtonKind.Secondary" [size]="ButtonSize.Medium"
            (clicked)="DismissAction()" [disabled]="primaryActionClicked || secondaryActionClicked">
            <ng-container label>{{ data.SecondaryButtonText }}</ng-container>
        </lib-button>
        <lib-button id="LibraryDialogPrimaryButton" class="text-nowrap" [kind]="ButtonKind.Danger"
            [size]="ButtonSize.Medium" [loading]="primaryActionClicked || secondaryActionClicked" (clicked)="PrimaryButtonClicked()">
            <ng-container label>{{ data.PrimaryButtonText }}</ng-container>
        </lib-button>
    </ng-container>
</lib-dialog-layout>
