import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { BaseViewComponent, ErrorMessages, Errors, FormContainerKind } from '@library/base';
import { filter, pairwise } from 'rxjs';


@Component({
    template: ''
    // No selector - cannot use this component directly
})
export class FormFieldBaseComponent<FormControlType, ViewType extends AbstractControl = FormControl<string>> extends BaseViewComponent implements OnInit, ControlValueAccessor {
    private _formControl!: FormControl<FormControlType>;
    private _ngControl?: NgControl;

    @Input()
    get formControl(): FormControl<FormControlType> {
        return this._formControl;
    }
    set formControl(value: FormControl<FormControlType>) {
        if (!value) {
            throw Error(`There is a form field component (${this.constructor.name}) with its formControl set to null or undefined.`);
        }
        // Do nothing. This input is only meant to enforce types and verify things are set up properly.
    }

    private _isLoadingOverride: boolean | undefined = undefined;
    @Input()
    get isLoading(): boolean {
        return this._isLoadingOverride != undefined ? this._isLoadingOverride : this.formControl.isLoading;
    }
    set isLoading(value: boolean | undefined) {
        this._isLoadingOverride = value;
    }

    private _isReadOnlyOverride: boolean | undefined = undefined;
    @Input()
    get isReadOnly(): boolean {
        return this._isReadOnlyOverride != undefined ? this._isReadOnlyOverride : this.formControl.isReadOnly;
    }
    set isReadOnly(value: boolean | undefined) {
        this._isReadOnlyOverride = value;
    }

    private _isViewOnlyOverride: boolean | undefined = undefined;
    @Input()
    get isViewOnly(): boolean {
        return this._isViewOnlyOverride != undefined ? this._isViewOnlyOverride : this.formControl.isViewOnly;
    }
    set isViewOnly(value: boolean | undefined) {
        this._isViewOnlyOverride = value;

        if(value) {
            this._viewControl?.disable();
        } else {
            this._viewControl?.enable();
        }
    }


    @Input() hideErrors: boolean = false;

    protected errors: Errors = new Errors();
    @Input()
    set errorMessages(value: ErrorMessages) {
        this.errors.Override(value);
    }
    @Input() qaTag: string = '';

    @Output() change = new EventEmitter<FormControlType>();
    @Output() blur = new EventEmitter();

    // View form control setup
    private _viewControl?: ViewType;
    protected _viewValue: any;

    private _onBlur!: () => void;
    private _onChange!: (_: any) => void;

    get viewControl(): ViewType {
        if (this._viewControl === undefined) {
            throw Error(`View form control is not initialized for form field component (${this.constructor.name}).`);
        }
        return this._viewControl;
    }
    protected set viewControl(value: ViewType) {
        this._viewControl = value;
    }

    // This approach is taken from https://material.angular.io/guide/creating-a-custom-form-field-control#ngcontrol
    constructor() {
        super();

        this._ngControl = inject(NgControl, {optional: true})!;
        if (this._ngControl) {
            this._ngControl.valueAccessor = this;
        }
    }

    override ngOnInit(): void {
        super.ngOnInit();
        if (this._ngControl) {
            // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
            this._formControl = this._ngControl.control as FormControl<FormControlType>;
        } else {
            // Use a dummy control (allows the use of a form field control without an underlying form control, if desired)
            this._formControl = new FormControl();
        }

        this.ObservePristine();
    }

    ObservePristine() {
        this._viewValue = this.viewControl.value;
        this.viewControl.valueChanges.pipe(filter(value => value != this._viewValue)).subscribe(value => {
            this.formControl.isDirty = true;
            this._viewValue = value;
        });
    }

    writeValue(value: FormControlType): void {
        this._viewValue = value;
        this.viewControl.setValue(value);
    }

    setDisabledState(state: boolean): void {
        if (state) {
            this._viewControl?.disable();
        } else {
            this._viewControl?.enable();
        }
    }

    registerOnTouched(fn: any): void {
        this._onBlur = fn;
    }
    
    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    OnBlur(): void {
        if(this._onBlur){
            this._onBlur();
        }
        this.blur.emit();
    }

    SetFormControlValue(value: FormControlType): void {
        if (this._onChange) {
            this._onChange(value);
        }
        this.change.emit(value);
    }

    get isDisabled(): boolean {
        return this.viewControl.disabled;
    }

    get FormContainerKind(): typeof FormContainerKind {
        return FormContainerKind;
    }
}
