<lib-dialog-layout>
    <ng-container title>
        <div class="d-flex justify-content-between">
            <h3>{{ data.Title }}</h3>
        </div>
    </ng-container>
    <ng-container content>
        <lib-iframe *ngIf="data.Text" class="data-hj-suppress" [src]="data.Text"></lib-iframe>
    </ng-container>
    <ng-container actions>
        <lib-button
            id="LibraryDialogSecondaryButton"
            [kind]="ButtonKind.Secondary"
            [size]="ButtonSize.Large"
            (clicked)="PrimaryAction()">
            <ng-container label>{{ data.PrimaryButtonText }}</ng-container>
        </lib-button>
    </ng-container>
</lib-dialog-layout>
