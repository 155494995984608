<lib-form-field-container
    [control]="formControl"
    [isLoading]="isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [isOptional]="isOptional"
    [isDisabled]="isDisabled"
    [prefixIcon]="prefixIcon"
    [suffixIcon]="suffixIcon"
    [kind]="FormContainerKind.TextArea"
    [errors]="errors"
    [textKind]="textKind">

    <ng-content label select="[label]"></ng-content>
    <ng-content readOnly select="[readOnly]"></ng-content>
    <ng-content hintMessage select="[hintMessage]"></ng-content>
    <ng-content prefixText select="[prefixText]"></ng-content>
    <ng-content suffixText select="[suffixText]"></ng-content>

    <textarea
        #textareaRef
        [ngClass]="{'text-area-code-block-font': textKind == TextKind.CodeBlock}"
        wrap="hard"
        [formControl]="viewControl"
        [placeholder]="placeholderText"
        [attr.autocorrect]="autocorrect ? 'on' : 'off'"
        [autocomplete]="autocorrect ? 'on' : 'off'"
        [spellcheck]="autocorrect"
        [maxLength]="maxCharacterLength"
        (blur)="OnBlur()"
        (change)="$event.stopPropagation()">
    </textarea>

</lib-form-field-container>
