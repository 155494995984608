import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BannerModule } from '@library/banner';
import { BaseModule, Environment, reCAPTCHASiteKey } from '@library/base';
import { ButtonModule } from '@library/button';
import { CardModule } from '@library/card';
import { FormFieldModule } from '@library/form-field';
import { IconModule } from '@library/icon';
import { MultiStepViewModule } from '@library/multi-step-view';
import { UtilityModule } from '@library/utility';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { DesignBlocksActionMenuComponent } from './action-menu/design-blocks-action-menu.component';
import { HtmlDesignBlockComponent } from './html/html-design-block.component';
import { SignupFormCustomFieldsComponent } from './signup-form/custom-fields/signup-form-custom-fields.component';
import { SignupFormDesignBlockComponent } from './signup-form/signup-form-design-block.component';
import { SignupFormDesignBlockStep1Component } from './signup-form/step1/signup-form-design-block-step1.component';
import { SignupFormDesignBlockStep2Component } from './signup-form/step2/signup-form-design-block-step2.component';
import { WidgetThankYouComponent } from './widget-thank-you/widget-thank-you.component';
import { TextDesignBlockComponent } from './text/text-design-block.component';
import { HeroDesignBlockComponent } from './hero/hero-design-block.component';
import { GalleryColumnDesignBlockComponent } from './gallery-column/gallery-column-design-block.component';
import { LayoutModule } from '@library/layout';
import { GalleryColumnDesignBlockItemComponent } from './gallery-column/gallery-column-design-block-item/gallery-column-design-block-item.component';
import { SignupFormDesignBlockStep3Component } from './signup-form/step3/signup-form-design-block-step3.component';
import { PaymentPageModule } from '@library/payment-page';
import { PhotoGalleryDesignBlockComponent } from './photo-gallery/photo-gallery-design-block.component';
import { PhotoGalleryDesignBlockItemComponent } from './photo-gallery/photo-gallery-design-block-item/photo-gallery-design-block-item.component';
import { ContactFormDesignBlockComponent } from './contact-form/contact-form-design-block.component';
import { LoginFormDesignBlockComponent } from './login-form/login-form-design-block.component';
import { BlogPostsDesignBlockComponent } from './blog/blog-posts-design-block.component';
import { ContactFormCustomFieldsComponent } from './contact-form/custom-fields/contact-form-custom-fields.component';
import { LocalizationModule } from '@library/localization';
import { IframeModule } from '@library/iframe';
import { ManagersModule } from '@library/managers';
import { EmptyStateModule } from '@library/empty-state';
import { MapDesignBlockComponent } from './map/map-design-block.component';
import { ImageActionMenuGeneralComponent } from './image-action-menu-general/image-action-menu-general.component';
import { ScrollToViewDirective } from './directives/scroll-to-view.directive';
import { MultiFactorAuthModule } from '@library/multi-factor-auth';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule } from "ng-recaptcha";
@NgModule({
    declarations: [
        TextDesignBlockComponent,
        SignupFormDesignBlockComponent,
        SignupFormDesignBlockStep1Component,
        SignupFormDesignBlockStep2Component,
        SignupFormCustomFieldsComponent,
        ContactFormDesignBlockComponent,
        ContactFormCustomFieldsComponent,
        WidgetThankYouComponent,
        DesignBlocksActionMenuComponent,
        HtmlDesignBlockComponent,
        HeroDesignBlockComponent,
        GalleryColumnDesignBlockComponent,
        GalleryColumnDesignBlockItemComponent,
        SignupFormDesignBlockStep3Component,
        LoginFormDesignBlockComponent,
        PhotoGalleryDesignBlockComponent,
        PhotoGalleryDesignBlockItemComponent,
        BlogPostsDesignBlockComponent,
        MapDesignBlockComponent,
        ImageActionMenuGeneralComponent,
        ScrollToViewDirective
    ],
    imports: [
        CommonModule,
        MultiStepViewModule,
        ReactiveFormsModule,
        BaseModule,
        FormFieldModule,
        ButtonModule,
        CardModule,
        BannerModule,
        IconModule,
        UtilityModule,
        LayoutModule,
        PaymentPageModule,
        LocalizationModule,
        IframeModule,
        ManagersModule,
        EmptyStateModule,
        MultiFactorAuthModule,
        RecaptchaModule,
    ],
    exports: [
        TextDesignBlockComponent,
        SignupFormDesignBlockComponent,
        HtmlDesignBlockComponent,
        HeroDesignBlockComponent,
        GalleryColumnDesignBlockComponent,
        GalleryColumnDesignBlockItemComponent,
        ContactFormDesignBlockComponent,
        LoginFormDesignBlockComponent,
        PhotoGalleryDesignBlockComponent,
        PhotoGalleryDesignBlockItemComponent,
        BlogPostsDesignBlockComponent, 
        MapDesignBlockComponent
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: reCAPTCHASiteKey } as RecaptchaSettings,
        },
    ],
})
export class DesignBlocksModule {
    // This allows us to set the environment used in a library module from the app
    public static setEnvironment(environment: Environment): ModuleWithProviders<DesignBlocksModule> {
        return {
            ngModule: DesignBlocksModule,
            providers: [{ provide: Environment, useValue: environment }]
        };
    }
}
