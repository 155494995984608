import { Pipe, PipeTransform } from '@angular/core';
import { TimeFormat } from '../date-time/sb-date-time';
import { SBTime } from '../date-time/sb-time';

@Pipe({
    name: 'formatTime'
})
export class LocalizationTimePipe implements PipeTransform {

    transform(value: string, pattern: TimeFormat) : string {
        try {
            return SBTime.FromISO(value).Render(pattern).replace(/\s/g, '\u00A0');
        } catch {
            return '';
        }
    }
}
